import { Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { AreaText } from 'components/AreaText';
import FavoriteButtonMini from 'components/favoriteButtons/FavoriteButtonMini';
import SubscribeButtonMini from 'components/subscribeButtons/SubscribeButtonMini';
import { useWidth } from 'hooks/useWidth';
import LotPageTags from 'pages/LotPage/LotPageTags';
import { useLotPageMultiStore, useLotPageStore } from 'stores/LotPageStore';
import s from './Header.module.scss';
import ButtonIconAction from 'components/ButtonIconAction/ButtonIconAction';
import useToggleState from 'hooks/useToggleState';
import { MessageModal } from 'components/MessageModal';
import { useRootStore } from 'stores/RootStore';
import { SaveLotPdf } from 'pages/LotPage/SaveLotPdf/SaveLotPdf';
import { useModalFilterText, useModalText } from 'components/ModalText/ModalText.context';
import { useUserGetTariffInfo } from 'hooks/usePopupFilterAuth';

type HeaderProps = {
  className?: string;
  showPrintPdf?: boolean;
};

export const HeaderActions = observer(({ showPrintPdf = false }: { showPrintPdf?: boolean }) => {
  const { userStore } = useRootStore();
  const { open: openModal, close: closeModal, opened: openedModal } = useToggleState();
  const { isDesktop } = useWidth();
  const { toggleOpen, changeMsg } = useModalText();

  const handleLoginButtonClick = () => {
    changeMsg(
      'Подключите пробный период на 3 дня за 1р. Далее полная стоимость тарифа - 5 000 р./месяц. Отменить подписку можно в любой момент.',
    );
    toggleOpen(true);
  };

  return (
    <>
      <div className={s.header__actions}>
        {showPrintPdf && <SaveLotPdf />}
        <SubscribeButtonMini isSubscribed={false} onClick={handleLoginButtonClick} withoutTooltip={!isDesktop} />
        <FavoriteButtonMini isFavorite={false} onClick={handleLoginButtonClick} withoutTooltip={!isDesktop} />
        {userStore.authorized && <ButtonIconAction onClick={openModal} />}
      </div>
      <MessageModal opened={openedModal} close={closeModal} />
    </>
  );
});

export const HeaderActionsAuth = observer(({ showPrintPdf = false }: { showPrintPdf?: boolean }) => {
  const { userStore } = useRootStore();
  const { open: openModal, close: closeModal, opened: openedModal } = useToggleState();
  const { isDesktop } = useWidth();
  const { lot } = useLotPageStore();
  const { isInFavorite, startToggleFavorite, isInSubscribed, startToggleSubscription } = lot.value;
  const { toggleOpen: toggleOpenTariff, changeMsg: changeMsgTariff } = useModalFilterText();
  const tariffUser = useUserGetTariffInfo();

  const handleFavoriteClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (tariffUser.tariffInfo && tariffUser.tariffInfo.lot_favorite) {
      startToggleFavorite();
    } else {
      changeMsgTariff('Для добавления лота в избранное оформите тариф');
      toggleOpenTariff(true);
    }
  };

  const handleSubscribeClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (tariffUser.tariffInfo && tariffUser.tariffInfo.lot_notification) {
      startToggleSubscription();
    } else {
      changeMsgTariff('Для подписки на изменения по лоту оформите тариф');
      toggleOpenTariff(true);
    }
  };

  return (
    <>
      <div className={s.header__actions}>
        {showPrintPdf && <SaveLotPdf />}
        <SubscribeButtonMini
          isSubscribed={isInSubscribed.value}
          onClick={handleSubscribeClick}
          withoutTooltip={!isDesktop}
        />
        <FavoriteButtonMini isFavorite={isInFavorite.value} onClick={handleFavoriteClick} withoutTooltip={!isDesktop} />
        {userStore.authorized && <ButtonIconAction onClick={openModal} />}
      </div>
      <MessageModal opened={openedModal} close={closeModal} />
    </>
  );
});

const Header: React.FC<HeaderProps> = ({ className, showPrintPdf = false }) => {
  const { userStore } = useRootStore();
  const { lot } = useLotPageMultiStore();
  const { width = window.innerWidth } = useWidth();
  const { objectType } = lot.value;
  const InnerHeader = userStore.authorized ? HeaderActionsAuth : HeaderActions;
  return (
    <div className={cn(s.header, className)}>
      <div className={s.header__title}>
        <Typography.Title level={1} className={s['header__title-text-wrapper']}>
          <span className={cn(s['header__title-text'], s['header__title_with-area'])}>{objectType}</span>
          <AreaText area={lot.value?.totalAreaFormatted || ''} />
        </Typography.Title>
        {width >= 996 && <InnerHeader showPrintPdf={showPrintPdf} />}
      </div>
      <LotPageTags className={s.header__tags} />
    </div>
  );
};

export default observer(Header);
