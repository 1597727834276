import { Button, Input, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import s from './ModalGeopgraphy.module.scss';
import { FilterOption } from 'entities/lotFilters';
import { useLotListStore } from 'stores/LotListCatalogStore/context';
import { searchFilterByTitle } from 'utils/filters';
import { MultiSelect } from 'components/MultiSelect';
import { SearchOutlined } from '@ant-design/icons';
import { FilterHeader } from 'pages/Home/ScreenFilters/Filters/Filters';

export const ModalGeopgraphy = observer(
  ({
    placeholderSelect,
  }: {
    className?: string;
    dropdownStyle?: React.CSSProperties;
    placeholder?: string;
    placeholderSelect?: string;
  }) => {
    const [open, setOpen] = useState(false);
    const [openSelect, setOpenSelect] = useState(false);
    const onClose = () => {
      setOpenSelect(false);
      setTimeout(() => setOpen(false), 100);
    };
    const { filters } = useLotListStore();
    const [search, setSearch] = React.useState('');

    const onSelectedAll = () => {
      setSearch('');
      filters.geography.setSelected(filters.geography.getAllOptionsIds || []);
    };
    const onClearSelected = () => {
      setSearch('');
      filters.geography.setSelected(null);
    };

    const onChange = (id: number, data?: FilterOption) => {
      const isChecked = filters.geography.selectedValue.hasItem(id);
      const ids = filters.geography.getChildrensSelectedValues(id);
      ids.push(id);
      if (isChecked) {
        const values = filters.geography.selectedValue.value.filter((e) => !~ids.indexOf(e));
        filters.geography.setSelected(values);
      } else {
        //@ts-ignore
        const values: number[] = [...new Set([...filters.geography.selectedValue.value, ...ids])];
        filters.geography.setSelected(values);
      }
      setSearch('');
    };

    const filtredOptions = React.useMemo(() => {
      if (search === '' || search.length < 4) {
        return filters.geography.optionsUnSorted;
      }
      return searchFilterByTitle(search, filters.geography.optionsUnSorted);
    }, [search, filters.geography.optionsUnSorted]);

    const onSearch = (value: string) => {
      setSearch(value);
    };
    const selectedCount = filters.geography.selectedOptions.length;

    return (
      <div>
        <button
          className={s.modalAction + ' ' + (selectedCount > 0 ? s.active : '')}
          onClick={() => {
            setOpenSelect(true);
            setOpen(true);
          }}
        >
          {selectedCount > 0 ? (
            'Выбрано: ' + selectedCount + ' значения'
          ) : (
            <span className={s.placeholder}>Регион</span>
          )}
        </button>
        <Modal
          open={open}
          onClose={onClose}
          onCancel={onClose}
          footer={<div />}
          wrapClassName={s.modalWrap}
          rootClassName={s.rootModal}
          width={'100vw'}
          height={'100vh'}
        >
          <div>
            <FilterHeader onClose={onClose} title="Регион" />
            <div className={s.content}>
              <div>
                <Input
                  className={s['input']}
                  placeholder={selectedCount > 0 ? 'Выбрано: ' + selectedCount + ' значения' : 'Регион'}
                  value={search}
                  onChange={(e) => {
                    onSearch(e.target.value);
                  }}
                  prefix={<SearchOutlined className={s.icon} />}
                />
              </div>
              <div className={s.list}>
                <MultiSelect
                  className={s.select}
                  placeholder={placeholderSelect}
                  options={filtredOptions}
                  selectedOptions={filters.geography.selectedOptions}
                  value={filters.geography.selectedValue.value}
                  onChange={onChange}
                  withSearch={false}
                  isOptionsLoading={false}
                  onSelectedAllCustom={onSelectedAll}
                  onClearSelectedCustom={onClearSelected}
                  dropdownStyle={{
                    maxHeight: 'auto',
                  }}
                  dropdownSelectsClass={s.dropdownSelects + ' ' + (open ? '' : s.none)}
                  actionsDropdownClass={s.actionsDropdown}
                  hideSelectedList
                  dropdownMulti
                  withActions
                  defaultOpen={openSelect}
                />
              </div>
              <Button className={s.btn} disabled={selectedCount === 0} onClick={onClose}>
                Применить
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  },
);
