import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { ServicesStoreProvider } from 'stores/ServicesStore/context';
import { PageContainer } from 'components/PageContainer';
import { FaqOnline } from 'pages/ServicesInfoPage/components/Faq/Faq';
import { RouteFullPath } from 'config/routes';
import { CardInteresting } from 'pages/ServicesInfoPage/components/CardInteresting/CardInteresting';
import { FormServicesOrder } from 'components/FormServicesOrder/FormServicesOrder';
import { BannerFull } from 'pages/ServicesInfoPage/components/BannerFull/BannerFull';
import { useNavigate } from 'react-router-dom';
import bannerImg from 'assets/online/banner.png';
import bannerMobileImg from 'assets/online/banner-mobile.png';
import legalImg from 'assets/escort/legal.png';
import escortImg from 'assets/audit-legal/escort.png';
import sellImg from 'assets/escort/sell.png';
import s from './OnlinePage.module.scss';
import { GridProcessSection } from './components/GridProcess/GridProcess';
import { Online } from './components/Online/Online';
import { SliderImages } from './components/SliderImages/SliderImages';
import { apiCustom } from 'utils/api';
import { apiUrls } from 'config/apiUrls';

type BannerData = {
  id: number;
  slug: string;
  header: string;
  cost: string;
  free_uses_text: string;
  tariff_info: string;
  service: number;
};

const ServisesInfoOnlinePage = () => {
  const navigate = useNavigate();
  const [bannerData, setBannerData] = useState<BannerData | null>(null);
  useEffect(() => {
    apiCustom<BannerData>({
      url: apiUrls.serviceBanner('online'),
      method: 'GET',
    }).then((res) => {
      console.log(res);
      if (!res.isError) {
        setBannerData(res.data);
      }
    });
  }, []);

  return (
    <ServicesStoreProvider>
      <BannerFull
        image={{ 540: bannerImg, 240: bannerMobileImg }}
        title={'Онлайн-показ объектов недвижимости '}
        description={'Смотрите объекты в реальном времени, не тратя время на дорогу'}
      />

      <PageContainer className={cn(s['lot-page'], s['lot-page-padding'])} withoutBottomPadding withoutTopPadding>
        <h2 className={s.title + ' ' + s.titleSection + ' ' + s.titleSectionProcess}>Наши преимущества</h2>
        <GridProcessSection />
        {bannerData && (
          <div className={s.smallBanner}>
            <div className={s.smallBannerContent}>
              <p className={s.title + ' ' + s.smallBannerTitle}>
                Стоимость <br />
                <span>{bannerData.cost} ₽</span>
              </p>
              <p className={s.text + ' ' + s.smallBannerText}>
                {bannerData.header} <span className={s.smallBannerChip}> {bannerData.free_uses_text}</span>
              </p>
            </div>
            <img
              src={sellImg}
              className={s.smallBannerImg}
              width={60}
              height={60}
              loading="lazy"
              decoding="async"
              alt=""
            />
          </div>
        )}

        <h2 className={s.title + ' ' + s.titleOnline}>Шаги трансляции</h2>
        <Online />
      </PageContainer>

      <SliderImages />

      <PageContainer className={cn(s['lot-page'], s['lot-page-mobile'])} withoutBottomPadding withoutTopPadding>
        <section className={s.formSection} id="form">
          <FormServicesOrder title={`Оставьте заявку <br />на дистанционный просмотр объекта`} />
        </section>
        <section className={s.servicesMoreSection}>
          <h2 className={s.title + ' ' + s.titleMore}>Может быть интересно</h2>
          <div className={s.rowMore}>
            <CardInteresting
              title={'Сопровождение сделки'}
              description={'Комплексная услуга, которая позволяет обеспечить безопасность и юридическую чистоту сделки'}
              image={escortImg}
              onClick={() => navigate('/' + RouteFullPath.services_escort)}
            />
            <CardInteresting
              title={'Юридический аудит'}
              description={'Минимизируйте риски, доверив проверку документов профессионалам'}
              image={legalImg}
              onClick={() => navigate('/' + RouteFullPath.services_audit_legal)}
            />
          </div>
        </section>
        <section className={s.sectionFaq}>
          <h2 className={s.title + ' ' + s.titleFaq}>Частые вопросы</h2>
          <FaqOnline />
        </section>
      </PageContainer>
    </ServicesStoreProvider>
  );
};

export default observer(ServisesInfoOnlinePage);
