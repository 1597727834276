import { apiUrls } from 'config/apiUrls';
import { IAdditionalServicePriceData } from 'entities/services/services';
import { IReactionDisposer } from 'mobx';
import { ListModel } from 'models/ListModel';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { AdditionaServicePriceModel } from 'models/ServiceModel';
import { LocalStore } from 'stores/LocalStore';
import { BaseResponse } from 'types/meta';
import { apiCustom } from 'utils/api';

export class ServicesStore extends LocalStore {
  private api = apiCustom;
  private readonly disposers: IReactionDisposer[] = [];
  readonly loadingStage: LoadingStageModel = new LoadingStageModel();
  readonly loadingServicesStage: LoadingStageModel = new LoadingStageModel();
  readonly auditLigalList: ListModel<AdditionaServicePriceModel, number> = new ListModel<
    AdditionaServicePriceModel,
    number
  >();

  constructor() {
    super();
  }

  fetchaAdditionalServicesPriceBySlug = async (
    slug: string,
    replace = true,
  ): Promise<BaseResponse<ListModel<AdditionaServicePriceModel, number>>> => {
    if (replace) {
      this.auditLigalList.setIsAllLoaded(false);
      this.auditLigalList.isReplaceLoading.change(true);
    }

    this.auditLigalList.loadingStage.loading();
    const response = await this.api<AdditionaServicePriceModel[]>({
      url: apiUrls.additionalServicesAdditionalServicesPrice(slug),
      method: 'GET',
    });

    if (response.isError) {
      this.auditLigalList.loadingStage.error();
      return { isError: true };
    }

    this.auditLigalList.fillByRawData<IAdditionalServicePriceData>(
      response.data,
      (raw) => {
        const model = AdditionaServicePriceModel.fromJson(raw);
        return {
          entity: model,
          key: model.id,
        };
      },
      replace,
    );
    this.auditLigalList.isInitialLoading.change(false);
    this.auditLigalList.isReplaceLoading.change(false);
    this.auditLigalList.total.change(response.data.length);
    this.auditLigalList.setIsAllLoaded(true);
    this.auditLigalList.loadingStage.success();

    return { isError: false, data: this.auditLigalList };
  };

  additionalServicesAnonymRequests = async (data: {
    name: string;
    phone: string;
    email: string;
    lot_url?: string | null;
  }): Promise<boolean> => {
    try {
      const values = { ...data };
      if (
        'lot_url' in values &&
        (values['lot_url'] === '' || values['lot_url'] == null || values['lot_url'] === undefined)
      ) {
        delete values['lot_url'];
      }
      this.loadingStage.loading();
      const response = await this.api<object>({
        url: `${apiUrls.additionalServicesAnonymRequests}`,
        method: 'POST',
        data: values,
      });

      if (response.isError) {
        throw new Error('Req Error');
      }
      this.loadingStage.success();
      return true;
    } catch (error) {
      this.loadingStage.error();
      return false;
    }
  };

  sendOrder = async (values: {
    cost: string;
    document?: string | null;
    user: number;
    service: number;
    user_tariff: number;
    lot: number | null;
    slug: string;
  }): Promise<boolean> => {
    try {
      this.loadingStage.loading();
      const response = await this.api<object>({
        url: `${apiUrls.additionalServicesRequests}`,
        method: 'POST',
        data: {
          status: 'received',
          ...values,
        },
      });

      if (response.isError) {
        throw new Error('Req Error');
      }
      this.loadingStage.success();
      return true;
    } catch (error) {
      this.loadingStage.error();
      return false;
    }
  };

  destroy(): void {
    super.destroy();
    this.disposers.forEach((disposer) => disposer());
  }
}
