import * as React from 'react';

import ImgCarousel, { CarouselImageSize } from 'components/ImgCarousel';
import { ElementId } from 'config/ids';
import { useLotPageMultiStore } from 'stores/LotPageStore';
import { ILotImage } from 'entities/lot';
import defaultImage from 'assets/default_text.png';
import { ImageModel } from 'models/ImageModel';
import { useRootStore } from 'stores/RootStore';
import { useModalText } from 'components/ModalText/ModalText.context';

type LotImagesCarouselProps = {
  className?: string;
  withoutThumbnails?: boolean;
};

const LotImagesCarousel: React.FC<LotImagesCarouselProps> = ({ className, withoutThumbnails }) => {
  const { userStore } = useRootStore();
  const { lot } = useLotPageMultiStore();
  const { toggleOpen, changeMsg } = useModalText();
  const empty_images =
    'image_count' in lot.value
      ? lot.value.image_count === 0
        ? []
        : (Array((lot.value.image_count as number) - 1)
            .fill(0)
            .map((_, index) =>
              ImageModel.fromJson(
                {
                  type: 'jpg',
                  url: defaultImage,
                },
                index,
                '',
                false,
              ),
            ) as ILotImage[])
      : [];

  return (
    <ImgCarousel
      id={ElementId.lotPageImageCarouselDesktop}
      className={className}
      images={lot.value.images ? [...lot.value.images, ...empty_images] : []}
      imgSize={CarouselImageSize.l}
      withoutThumbnails={withoutThumbnails}
      onThumbnailClick={
        !userStore.authorized
          ? () => {
              changeMsg(
                'Подключите пробный период на 3 дня за 1р. Далее полная стоимость тарифа - 5 000 р./месяц. Отменить подписку можно в любой момент.',
              );
              toggleOpen(true);
            }
          : undefined
      }
    />
  );
};

export default React.memo(LotImagesCarousel);
