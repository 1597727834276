import { Button as ButtonAntd } from 'antd';
import cn from 'classnames';
import * as React from 'react';
import s from './Button.module.scss';

type ButtonIconProps = {
  id?: string;
  className?: string;
  white?: boolean;
  fillDisabled?: boolean;
  outline?: boolean;
} & React.ComponentProps<typeof ButtonAntd>;

const Button = ({
  children,
  id,
  className,
  white,
  ...props
}: React.PropsWithChildren<ButtonIconProps>): React.ReactElement<ButtonIconProps> => {
  return (
    <ButtonAntd
      id={id}
      className={cn(
        className,
        s['btn'],
        white && s['btn_white'],
        props?.outline && s.outline,
        props?.fillDisabled && s.fill_disabled,
      )}
      {...props}
    >
      {children}
    </ButtonAntd>
  );
};

export default React.memo(Button);
