import React from 'react';
import s from './BannerFull.module.scss';
import { useWidth } from 'hooks/useWidth';
import { Button } from 'components/Button';
export const BannerFull = ({
  image,
  title,
  description,
}: {
  image: { 540: string; 240: string };
  title: string;
  description: string;
}) => {
  const { width = window.innerWidth } = useWidth();
  const gotoForm = () => {
    const element = document.getElementById('form');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  };
  return (
    <div className={s.banner}>
      <picture>
        <source srcSet={image[540]} media="(min-width: 540px)" />
        <source srcSet={image[240]} media="(min-width: 240px)" />

        <img src={image[240]} loading="lazy" decoding="async" className={s.bannerImage} />
      </picture>

      <div className={s.bannerContent}>
        <h1 className={s.title + ' ' + s.titleBanner}>{title}</h1>
        <p className={s.text + ' ' + s.textBanner}>{description}</p>
        {width > 480 && (
          <Button className={s.btnBanner} white onClick={gotoForm}>
            Оставить заявку
          </Button>
        )}
      </div>
      {width <= 480 && (
        <Button className={s.btnBanner + ' ' + s.absolute} onClick={gotoForm}>
          Оставить заявку
        </Button>
      )}
    </div>
  );
};
