export type FilterOption<ID = number> = {
  id: ID;
  title: string;
  type?: string;
  children?: FilterOption<ID>[];
  options?: {
    parentID?: ID;
    chidrenIds: number[];
  };
};

export type RangeFilter = {
  from: number;
  to: number;
};

type LotCommonFilters = {
  auction_type: FilterOption[];
  status: FilterOption[];
  form_type: FilterOption[];
  object_type: FilterOption[];
};

type LotCommonHistoryFilters = {
  aggregated_type: { slug: string; title: string }[];
  auction_type: FilterOption[];
  status: FilterOption[];
  form_type: FilterOption[];
  object_type: FilterOption[];
};

export type LotCommonFiltersResponse = {
  filters: LotCommonFilters;
};

export interface IFilterGeography {
  id: number;
  name: string;
  final_name: string;
  type: string;
  type_human: string;
  code: string;
  children: IFilterGeography[];
}

export type LotFiltersResponse = {
  filters: LotCommonFilters & {
    object_type: FilterOption[];
    square: RangeFilter;
    price: RangeFilter;
    floor: RangeFilter;
    amount_of_floors: RangeFilter;
    aggregated_type: { slug: string; title: string }[];
    geography: IFilterGeography[];
    sections: { slug: string; title: string }[];
    source: { slug: string; title: string }[];
    aggregated_auction_format: { slug: string; title: string }[];
    aggregated_auction_type: { slug: string; title: string }[];
  };
};

export type LotProfileFiltersResponse = LotCommonFiltersResponse;
export type LotProfileHistoryFiltersResponse = { filters: LotCommonHistoryFilters };

export enum SortOption {
  new = 'newness',
  popularity = 'popularity',
  price = 'price',
  bidEndDate = 'bid_end_date',
}

export enum SortDirection {
  asc = 'asc',
  desc = 'desc',
}

export type SortOptionItem = {
  id: SortOption;
  title: string;
  withSortDirection: boolean;
  defaultDirection: SortDirection;
};

export enum ProfileListType {
  favorites = 'favorites',
  bids = 'bids',
  subs = 'subs',
}

export enum ProfileFavoriteListType {
  favorites = 'favorites',
  favorites_notif = 'favorites_notif',
  favorites_unnotif = 'favorites_unnotif',
}

export type ProfileListPayload = {
  params: {
    limit: number;
    offset: number;
    order: SortOption;
    order_direction: SortDirection;
  };
  list_type: ProfileListType;
  filters: {
    auction_type: number[];
    form_type: number[];
    status: number[];
  };
};

export type ProfileListFavoritePayload = {
  params: {
    limit: number;
    offset: number;
    order: SortOption;
    order_direction: SortDirection;
  };
  list_type?: string;
  favorites: boolean | null;
  subscribed: boolean | null;
  filters: {
    auction_type: number[];
    form_type: number[];
    status: number[];
  };
};

export type ProfileListHistoryPayload = {
  params: {
    limit: number;
    offset: number;
    order: SortOption;
    order_direction: SortDirection;
  };
  list_type?: string;
  filters: {
    only_visited: boolean;
    auction_type: number[];
    form_type: number[];
    status: number[];
  };
};

export enum FloorOptions {
  NO_FLOOR = 'NO_FLOOR',
  BASEMENT = 'BASEMENT',
  NOT_FIRST = 'NOT_FIRST',
  NOT_LAST = 'NOT_LAST',
  FIRST = 'FIRST',
  LAST = 'LAST',
}
