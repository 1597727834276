import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { LotsListProps as BaseLotsListProps } from 'components/lot/types';
import { ListModel } from 'models/ListModel';
import { LotModel } from 'models/LotModel';
import { useLotListProfileRobotStore } from 'stores/LotListProfileRobotStore/context';
import { LotListPagination } from 'components/lot/LotsListPagination';
import { LotCardSmallIntersection } from 'components/lot/LotCardSmall/LotCardSmall';
import { useRootStore } from 'stores/RootStore';
import { Empty, Pagination } from 'antd';
import { getCurrentPage } from 'utils/page';
import s from './LotsList.module.scss';
import { useUserGetTariffInfo } from 'hooks/usePopupFilterAuth';
import { RouteFullPath } from 'config/routes';
import { Link } from 'react-router-dom';
import { AuthStep } from 'stores/AuthStore';

type LotsListProps = {
  className?: string;
  lots: ListModel<LotModel, number>;
  emptyText: string;
};

const LotsList = ({ className, lots, emptyText }: LotsListProps): React.ReactElement<LotsListProps> => {
  const store = useLotListProfileRobotStore();
  const { userStore, robotStore, authStore } = useRootStore();
  const refWrap = React.useRef<null | HTMLDivElement>(null);
  const timerDebounceRef = React.useRef<NodeJS.Timeout | null>(null);
  const refViews = React.useRef<number[]>([]);
  const tariffUser = useUserGetTariffInfo();

  const handleAddView = (id: number | number[]) => {
    if (userStore.authorized && robotStore.robotItem?.value !== null && robotStore.robotItem.value.new_lots_count > 0) {
      if (Array.isArray(id)) {
        refViews.current = [...refViews.current, ...id];
      } else {
        refViews.current.push(id);
      }
    }
  };

  const handleDebounceScroll = React.useCallback(() => {
    if (
      userStore.authorized &&
      robotStore.robotItem?.value !== null &&
      refViews.current.length > 0 &&
      robotStore.robotItem.value.new_lots_count > 0
    ) {
      if (timerDebounceRef.current) {
        clearTimeout(timerDebounceRef.current);
      }
      timerDebounceRef.current = setTimeout(() => {
        robotStore.addViewItemsRobot(robotStore.robotItem!.value!.id, refViews.current);
      }, 500);
    }
  }, [userStore.authorized, robotStore.robotItem, refViews.current]);

  const props = React.useMemo<BaseLotsListProps<LotModel>>(() => {
    return {
      className: className,
      lots: lots,
      emptyText: emptyText,
      CardComponent: (props) => <LotCardSmallIntersection {...props} onView={handleAddView} />,
    };
  }, [
    emptyText,
    store.filters.setPage,
    lots,
    userStore.authorized,
    robotStore.robotItem,
    robotStore.robotItem.value?.id,
  ]);

  React.useEffect(() => {
    if (document) {
      document.addEventListener('scroll', handleDebounceScroll);
    }

    return () => {
      authStore.authStep.change(AuthStep.initial);
      authStore.mode.change('login');
      document?.removeEventListener('scroll', handleDebounceScroll);
    };
  }, []);

  const onClickEmptyText = () => {
    authStore.authStep.change(AuthStep.registration_success);
    authStore.mode.change('register');
    authStore.popupController.open();
  };

  return (
    <div ref={refWrap} className={className}>
      {tariffUser.tariffInfo && tariffUser.tariffInfo.smart_search ? (
        <LotListPagination {...props} grid />
      ) : (
        <Empty
          className={s['lots-list__empty']}
          description={
            userStore.user?.tariffType === 'BASIC' ? (
              <span className={s.empty__link} onClick={onClickEmptyText} role="button">
                Подключите пробный период для просмотра
              </span>
            ) : (
              <Link to={RouteFullPath.tariffs}>Оформите тариф для просмотра</Link>
            )
          }
        />
      )}

      {lots.total.value && lots.total.value > 10 && tariffUser.tariffInfo && tariffUser.tariffInfo.smart_search ? (
        <div className={s.pagination}>
          <Pagination
            current={getCurrentPage(store.lots.total.value, store.filters.offset)}
            total={store.lots.total.value || 0}
            onChange={(page) => {
              store.filters.setPage(page - 1);
              refViews.current = [];
              if (refWrap && refWrap.current) window.scrollTo({ behavior: 'smooth', top: refWrap.current.offsetTop });
            }}
          />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default observer(LotsList);
