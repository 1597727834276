import React from 'react';
import s from './SliderImages.module.scss';
import Slider from 'components/Slider';
import { Pagination, Navigation } from 'swiper/modules';
import slide1 from 'assets/online/s1.png';
import slide2 from 'assets/online/s2.png';
import slide3 from 'assets/online/s3.png';
import slide4 from 'assets/online/s4.png';
import slide5 from 'assets/online/s5.png';
import slide6 from 'assets/online/s6.png';
import { PageContainer } from 'components/PageContainer';
import ArrowRoundIcon from 'components/icons/ArrowRoundIcon/ArrowRoundIcon';

export const SliderImages = () => {
  const pagination = {
    clickable: true,
    renderBullet: (index: number, className: string) => {
      return `<span class="${className} ${s.bullet}" > </span>`;
    },
    el: '.paginaton-service',
  };
  return (
    <section className={s.section}>
      <PageContainer className={s.padding_null} withoutBottomPadding withoutTopPadding>
        <div className={s.header}>
          <h2 className={s.title + ' ' + s.titleExample}>Примеры</h2>
          <div className={s.arrows}>
            <button className={s.arrowBtn + ' prevBtn-images'}>
              <ArrowRoundIcon />
            </button>
            <button className={s.arrowBtn + ' ' + s.rotate + ' nextBtn-images'}>
              <ArrowRoundIcon />
            </button>
          </div>
        </div>
      </PageContainer>
      <div className={s.slider}>
        <Slider
          spaceBetween={20}
          slideClass={s.slide}
          pagination={pagination}
          loop
          slides={[slide1, slide2, slide3, slide4, slide5, slide6].map((item, index) => (
            <div className={s.slide} key={index}>
              <img src={item} alt="" />
            </div>
          ))}
          onSlideChange={() => {}}
          slidesPerView={'auto'}
          modules={[Pagination, Navigation]}
          navigation={{
            prevEl: '.prevBtn-images',
            nextEl: '.nextBtn-images',
          }}
          rewind
          watchOverflow
        />
        <div className={'paginaton-service ' + s.pagination} />
      </div>
    </section>
  );
};
