import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { RootStore } from './RootStore';

type RootStoreContextValue = RootStore;

const RootStoreContext = React.createContext<RootStoreContextValue | null>(null);

export const RootStoreContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const rootStore = React.useRef(new RootStore({ navigate }));

  React.useEffect(() => {
    Promise.all([
      rootStore.current.tariffsStore.fetchTariffs({ replace: true }),
      rootStore.current.tariffsStore.fetchTariffTrial(),
    ]);

    rootStore.current.authStore.getAuthorizeUser().then((res) => {
      if (!res.isError) {
        Promise.all([
          rootStore.current.robotStore.fetchRobotItems({ replace: true }),
          rootStore.current.notificationStore.fetchNotificationItems({ replace: true }),
          rootStore.current.notificationStore.getNotificationsNewCount(),
          rootStore.current.profileService.init(),
          rootStore.current.profileNotificationSubscribe.fetchNotificationSubscribeItems({ replace: true }),
          rootStore.current.userStore.getUsersTariffs({ replace: true }),
        ]);
      }
    });
  }, [rootStore.current]);

  return <RootStoreContext.Provider value={rootStore.current}>{children}</RootStoreContext.Provider>;
};

export const useRootStore = () => {
  const context = React.useContext(RootStoreContext);

  if (!context) {
    throw new Error('RootStoreContext was not provided');
  }

  return context;
};

export const useAuthStore = () => {
  const context = React.useContext(RootStoreContext);

  if (!context) {
    throw new Error('RootStoreContext was not provided');
  }

  return context.authStore;
};

export const useNotificationStore = () => {
  const context = React.useContext(RootStoreContext);

  if (!context) {
    throw new Error('RootStoreContext was not provided');
  }

  return context.notificationStore;
};

export const useThemeStore = () => {
  const context = React.useContext(RootStoreContext);

  if (!context) {
    throw new Error('RootStoreContext was not provided');
  }

  return context.themeStore;
};
