import React from 'react';
import s from './PriceGrid.module.scss';
import { observer } from 'mobx-react-lite';
import { useServicesStore } from 'stores/ServicesStore/context';
import { formatNumberToString } from 'utils/formatNumberToString';
import bg from 'assets/bg-img.png';

export const PriceGrid = observer(() => {
  const { auditLigalList } = useServicesStore();
  console.log(auditLigalList.items);
  return auditLigalList.items.length > 0 ? (
    <div className={s.grid}>
      <div className={s.itemBig}>
        <img src={bg} alt="" />
        <div className={s.itemBigContent}>
          <div className={s.itemBigTitle}>Юридический аудит</div>
          <div className={s.itemBigDesc}>Сделка начинается с анализа потенциальных рисков</div>
          <div className={s.itemBigPrice}>20 000 ₽</div>
        </div>
      </div>
      <div className={s.itemItem + ' ' + s.itemTwo}>
        <span className={s.itemItemName}>на тарифе «{auditLigalList.items[1].tariff_name}»</span>
        <span className={s.itemItemPrice}>
          {' '}
          {formatNumberToString(parseInt(auditLigalList.items[1].cost), { minimumFractionDigits: 0 })} ₽
          <span>-{auditLigalList.items[1].discount}%</span>
        </span>
      </div>
      <div className={s.itemItem + ' ' + s.itemThree}>
        <span className={s.itemItemName}>на тарифе «{auditLigalList.items[2].tariff_name}»</span>
        <span className={s.itemItemPrice}>
          {' '}
          {formatNumberToString(parseInt(auditLigalList.items[2].cost), { minimumFractionDigits: 0 })} ₽
          <span>-{auditLigalList.items[2].discount}%</span>
        </span>
      </div>
    </div>
  ) : (
    <div />
  );
});
