import React, { ReactNode } from 'react';
import { IconText } from 'components/IconText/IconText';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores/RootStore';
import { useNavigate } from 'react-router-dom';
import { RouteFullPath } from 'config/routes';
import { AuthStep } from 'stores/AuthStore';
import s from './IconTextAuth.module.scss';

export const IconTextAuth = observer(
  (props: { icon?: ReactNode; text?: string; onClick?: () => void; className?: string }) => {
    const { userStore, authStore } = useRootStore();
    const navigate = useNavigate();
    const onClick = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (userStore.authorized && userStore.user?.tariffType === 'BASIC') {
        authStore.authStep.change(AuthStep.registration_success);
        authStore.mode.change('register');
        authStore.popupController.open();
        props.onClick && props.onClick();
      } else if (!userStore.authorized) {
        authStore.mode.change('register');
        authStore.popupController.toggle();
        props.onClick && props.onClick();
      } else {
        props.onClick && props.onClick();
        navigate(RouteFullPath.tariffs);
      }
    };
    const getText = () => {
      if (userStore.authorized && userStore.user?.tariffType === 'BASIC') {
        return 'Подключите пробный период';
      }
      return userStore.authorized ? 'Оформите тариф для просмотра' : 'Подключите пробный период';
    };
    return (
      <IconText
        icon={props.icon}
        className={props.className + ' ' + s.action}
        onClick={onClick}
        text={props.text ? props.text : getText()}
      />
    );
  },
);
