import { E164Number } from 'libphonenumber-js/core';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

import { Validator, ValidatorResult } from 'types/validator';

export const phoneValidator: Validator<E164Number | null, undefined> = (value: E164Number | null): ValidatorResult => {
  if (!value) {
    return null;
  }

  if (!isPossiblePhoneNumber(value)) {
    return 'Введён неверный формат номера';
  }

  return null;
};

export const phoneStringValidator: Validator<string | null, undefined> = (value: string | null): ValidatorResult => {
  if (value === null || value === '' || value.includes('_')) {
    return 'Введите верно номер';
  }

  return null;
};

export const emailValidator: Validator<string, undefined> = (value: string): ValidatorResult => {
  if (value.includes('-')) {
    return 'Некорректный email';
  }
  if (!/^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gim.test(value)) {
    return 'Некорректный email';
  }

  return null;
};

export const sitelValidator: Validator<string, undefined> = (value: string): ValidatorResult => {
  if (value == undefined || value === null || value === '') {
    return null;
  }

  if (
    (value.includes('kupilot') || value.includes('localhost')) &&
    (value.includes('https') || value.includes('http'))
  ) {
    return null;
  } else {
    return 'Введите верно сайт';
  }
};

type StringValidatorCreator = (params: {
  minLength?: number;
  maxLength?: number;
  replace?: string;
}) => Validator<string, undefined>;

export const stringLengthValidator: StringValidatorCreator =
  ({ minLength = 0, maxLength = 0, replace }) =>
  (value: string): ValidatorResult => {
    const _value = replace ? value.replaceAll(replace, '') : value;
    if (minLength && _value.length < minLength) {
      return `Минимальное количество символов — ${minLength}`;
    }

    if (maxLength && _value.length > maxLength) {
      return `Максимальное количество символов — ${maxLength}`;
    }

    return null;
  };

type NumberMinMaxValidatorCreator = (params: {
  min?: number;
  max?: number;
  minText?: string;
  maxText?: string;
  printValue?: boolean;
}) => Validator<string, undefined>;

export const numberMinMaxValidator: NumberMinMaxValidatorCreator =
  ({ min = 0, max = 0, minText = 'Минимальное значение', maxText = 'Максимальное значение', printValue = false }) =>
  (value: string): ValidatorResult => {
    if (Number.isNaN(parseInt(value))) {
      return 'Введите число';
    }
    const num = parseInt(value);

    if (num < min) {
      return printValue ? `${minText} ${min}` : `${minText}`;
    }

    if (num > max) {
      return printValue ? `${maxText} ${max}` : maxText;
    }

    return null;
  };

export const stringMaskValidator =
  () =>
  (value: string): ValidatorResult => {
    if (value === null || value === '' || value.includes('_')) {
      return 'Поле обязательно для заполнения';
    }

    return null;
  };

export const confirmhPasswordValidator =
  () =>
  (value: string, confirmValue?: string): ValidatorResult => {
    if (confirmValue === undefined) {
      return `Укажите значение для сравнения`;
    }

    if (value !== confirmValue) {
      return `Пароли не совпадают`;
    }

    return null;
  };
