import * as React from 'react';
import { BaseIconProps } from '../commonProps';

const CircleProgressIconOne = (props: BaseIconProps): React.ReactElement<BaseIconProps> => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M32 62C48.5685 62 62 48.5685 62 32C62 15.4315 48.5685 2 32 2C15.4315 2 2 15.4315 2 32C2 48.5685 15.4315 62 32 62Z"
        fill="white"
        stroke="#D9D9D9"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke-dasharray="6 6"
      />
      <path
        d="M62 32C62 15.4315 48.5685 2 32 2"
        stroke="#5482E0"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.4291 41V38.946H31.7191V24.724H32.9411L27.8191 27.896V25.53L32.3951 22.67H34.1371V38.946H38.1671V41H27.4291Z"
        fill="#252525"
      />
    </svg>
  );
};

const CircleProgressIconTwo = (props: BaseIconProps): React.ReactElement<BaseIconProps> => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle
        cx="32"
        cy="32"
        r="30"
        fill="white"
        stroke="#D9D9D9"
        strokeWidth="3"
        strokeLinecap="round"
        stroke-dasharray="6 6"
      />
      <path
        d="M26.4151 41V39.206L32.8111 32.498C33.6431 31.6487 34.2584 30.834 34.6571 30.054C35.0731 29.274 35.2811 28.468 35.2811 27.636C35.2811 26.6133 34.9604 25.842 34.3191 25.322C33.6951 24.7847 32.7764 24.516 31.5631 24.516C30.6271 24.516 29.7431 24.6893 28.9111 25.036C28.0964 25.3653 27.3164 25.8767 26.5711 26.57L25.7131 24.724C26.4237 24.0307 27.3164 23.476 28.3911 23.06C29.4831 22.644 30.6271 22.436 31.8231 22.436C33.6951 22.436 35.1424 22.8693 36.1651 23.736C37.1877 24.5853 37.6991 25.8073 37.6991 27.402C37.6991 28.5633 37.4131 29.6727 36.8411 30.73C36.2864 31.77 35.4631 32.8447 34.3711 33.954L28.7811 39.622V38.946H38.4531V41H26.4151Z"
        fill="#252525"
      />
      <path
        d="M62 32C62 15.4315 48.5685 2 32 2"
        stroke="#5482E0"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62 32C62 48.5685 48.5685 62 32 62"
        stroke="#5482E0"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const CircleProgressIconThee = (props: BaseIconProps): React.ReactElement<BaseIconProps> => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle
        cx="32"
        cy="32"
        r="30"
        fill="white"
        stroke="#D9D9D9"
        strokeWidth="3"
        strokeLinecap="round"
        stroke-dasharray="6 6"
      />
      <path
        d="M62 32C62 15.4315 48.5685 2 32 2"
        stroke="#5482E0"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62 32C62 48.5685 48.5685 62 32 62"
        stroke="#5482E0"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 32C2 48.5685 15.4315 62 32 62"
        stroke="#5482E0"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.8751 41.234C30.6271 41.234 29.4311 41.0433 28.2871 40.662C27.1604 40.2807 26.2157 39.7433 25.4531 39.05L26.3111 37.178C27.1604 37.8713 28.0357 38.374 28.9371 38.686C29.8384 38.998 30.7917 39.154 31.7971 39.154C33.1317 39.154 34.1457 38.8767 34.8391 38.322C35.5324 37.75 35.8791 36.9353 35.8791 35.878C35.8791 34.838 35.5237 34.0493 34.8131 33.512C34.1197 32.9747 33.0971 32.706 31.7451 32.706H28.9371V30.704H31.5631C32.7071 30.704 33.6257 30.4093 34.3191 29.82C35.0124 29.2307 35.3591 28.4247 35.3591 27.402C35.3591 26.466 35.0384 25.7553 34.3971 25.27C33.7731 24.7673 32.8804 24.516 31.7191 24.516C29.7604 24.516 28.0617 25.2007 26.6231 26.57L25.7651 24.724C26.4931 23.996 27.3944 23.4327 28.4691 23.034C29.5437 22.6353 30.6791 22.436 31.8751 22.436C33.6951 22.436 35.1251 22.852 36.1651 23.684C37.2051 24.516 37.7251 25.6773 37.7251 27.168C37.7251 28.2947 37.4044 29.2653 36.7631 30.08C36.1391 30.8773 35.2811 31.406 34.1891 31.666V31.38C35.4717 31.6053 36.4684 32.134 37.1791 32.966C37.8897 33.7807 38.2451 34.8033 38.2451 36.034C38.2451 37.646 37.6731 38.92 36.5291 39.856C35.4024 40.7747 33.8511 41.234 31.8751 41.234Z"
        fill="#252525"
      />
    </svg>
  );
};

const CircleProgressIconFour = (props: BaseIconProps): React.ReactElement<BaseIconProps> => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="30" cy="30" r="28.5" fill="white" stroke="#5482E0" strokeWidth="3" strokeLinecap="round" />
      <path
        d="M32.0591 39V35.126H23.4531V33.384L32.4491 20.67H34.4251V33.202H37.1811V35.126H34.4251V39H32.0591ZM32.0591 33.202V23.53H32.6571L25.4031 33.774V33.202H32.0591Z"
        fill="#252525"
      />
    </svg>
  );
};

export { CircleProgressIconOne, CircleProgressIconTwo, CircleProgressIconThee, CircleProgressIconFour };
