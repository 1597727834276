import * as React from 'react';
import { BaseIconProps } from '../commonProps';

const SubIcon = (props: BaseIconProps): React.ReactElement<BaseIconProps> => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M19.1667 9.16663H0.833333C0.373096 9.16663 0 9.53972 0 9.99996C0 10.4602 0.373096 10.8333 0.833333 10.8333H19.1667C19.6269 10.8333 20 10.4602 20 9.99996C20 9.53972 19.6269 9.16663 19.1667 9.16663Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SubIcon;
