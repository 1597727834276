import React from 'react';
import { CardProcess } from '../CardProcess/CardProcess';
import s from './GridProcess.module.scss';
import g1Img from 'assets/online/g1.png';
import g2Img from 'assets/online/g2.png';
import g3Img from 'assets/online/g3.png';
import g4Img from 'assets/online/g4.png';
import g5Img from 'assets/online/g5.png';
import g6Img from 'assets/online/g6.png';

import gridImg1 from 'assets/online/gg1.png';
import gridImg2 from 'assets/online/gg2.png';
import { useWidth } from 'hooks/useWidth';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

export const GridProcess = () => {
  return (
    <div className={s.gridProcess}>
      <div className={s.paperCard + ' ' + s.gridProcessOne}>
        <img src={g1Img} alt="" />
        <h3 className={s.title}>
          Доступна опция <br />
          «Без трансляции»
        </h3>
        <p className={s.desc}>
          Если у вас не получится подключиться к трансляции, мы снимем для вас детализированный обзор и предоставим все
          материалы
        </p>
      </div>
      <div className={s.paperCard + ' ' + s.gridProcessTwo}>
        <img src={g2Img} alt="" />
        <div className={s.gridContentTwo}>
          <h3 className={s.title}>Экономия времени</h3>
          <p className={s.desc}>Проведите несколько просмотров в один день – без затрат времени на поездку</p>
        </div>
      </div>
      <div className={s.paperCard + ' ' + s.gridProcessThree}>
        <img src={g3Img} alt="" />
        <div className={s.gridContentThree}>
          <h3 className={s.title}>Детальное представление</h3>
          <p className={s.desc}>
            Вы можете детально изучить каждое <br />
            помещение объекта недвижимости и получить полное представление о его состоянии онлайн
          </p>
        </div>
      </div>

      <div className={s.paperCard + ' ' + s.gridProcessFour}>
        <img src={g4Img} alt="" />
        <h3 className={s.title}>Технический план</h3>
        <p className={s.desc}>Отправляем для ознакомления технический план объекта до подключения к показу</p>
      </div>
      <div className={s.paperCard + ' ' + s.gridProcessFive}>
        <img src={g5Img} alt="" />
        <h3 className={s.title}>Дистанционный просмотр</h3>
        <p className={s.desc}>Позволяет посмотреть объект, не выезжая на место</p>
      </div>
      <div className={s.paperCard + ' ' + s.gridProcessSix}>
        <img src={g6Img} alt="" />
        <h3 className={s.title}>
          Видео и фото{' '}
          <Tooltip
            overlayClassName={s['tooltip']}
            placement="top"
            title={'Так же отправляем отснятый видеоматериал после онлайн-показа'}
            trigger={['hover']}
          >
            {' '}
            <InfoCircleOutlined />{' '}
          </Tooltip>
        </h3>
        <p className={s.desc}>
          По окончании показа мы предоставим вам запись онлайн-показа, а также дополнительные фото и видео объекта
        </p>
      </div>
    </div>
  );
};

export const GridProcessMobile = () => {
  return (
    <div className={s.gridProcessMobile}>
      <CardProcess image={gridImg1} className={s.gridProcessMobileOne}>
        <div>
          <h3>Доступна опция «Без трансляции»</h3>
          <p>Снимем детализированный обзор и предоставим все материалы без подключения к трансляции</p>
        </div>
        <div>
          <h3>Экономия времени</h3>
          <p>Проведите несколько просмотров в один день – без затрат времени на поездку</p>
        </div>
      </CardProcess>
      <CardProcess image={gridImg2} className={s.gridProcessMobileTwo}>
        <div>
          <h3>Технический план</h3>
          <p>Ознакомьтесь с техническим планом объекта до подключения к показу</p>
        </div>
        <div>
          <h3>Видео и фото</h3>
          <p>По окончании показа отправим запись и дополнительные фото- и видеоматериалы объекта</p>
        </div>
      </CardProcess>
    </div>
  );
};

export const GridProcessSection = () => {
  const { width = window.innerWidth } = useWidth();
  return width <= 928 ? <GridProcessMobile /> : <GridProcess />;
};
