import React, { ReactElement } from 'react';
import s from './CardInfo.module.scss';

export const CardInfo = ({
  icon,
  title,
  desc,
  className = '',
  type = 'full',
}: {
  icon?: ReactElement;
  title: string;
  desc?: string;
  className?: string;
  type?: 'full' | 'icon_text' | 'text';
}) => {
  return (
    <div className={s.card + ' ' + className + ' ' + s[type]} data-type={type}>
      {type === 'full' && (
        <>
          <div className={s.icon + ' ' + s.full}>{icon}</div>
          <h4 className={s.title + ' ' + s.full}>{title}</h4>
          {desc && <p className={s.desc + ' ' + s.full} dangerouslySetInnerHTML={{ __html: desc }} />}
        </>
      )}
      {type === 'icon_text' && (
        <>
          <div className={s.icon}>{icon}</div>
          <p className={s.title + ' ' + s.icon_text} dangerouslySetInnerHTML={{ __html: title }} />
        </>
      )}

      {type === 'text' && (
        <>
          <h4 className={s.title + ' ' + s.titleText} dangerouslySetInnerHTML={{ __html: title }} />
          {desc && <p className={s.desc + ' ' + s.descText} dangerouslySetInnerHTML={{ __html: desc }} />}
        </>
      )}
    </div>
  );
};
