import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { BaseInput } from 'components/BaseInput';
import { MultiSelect } from 'components/MultiSelect';
import { useLotListStore } from 'stores/LotListCatalogStore/context';

type Props = {
  className?: string;
};

const AggregatedAuctionType = ({ className }: Props): React.ReactElement<Props> => {
  const { filters } = useLotListStore();

  return (
    <BaseInput className={className} title="Вид торгов">
      <MultiSelect<string>
        options={filters.aggregatedAuctionType.options}
        selectedOptions={filters.aggregatedAuctionType.selectedOptions}
        value={filters.aggregatedAuctionType.selectedValue.value}
        onChange={filters.aggregatedAuctionType.toggleValue}
        onSearch={filters.aggregatedAuctionType.handleSearch}
        searchValue={filters.aggregatedAuctionType.searchValue.value}
        isOptionsLoading={false}
        dropdownStyle={{
          zIndex: 1300,
        }}
      />
    </BaseInput>
  );
};

export default observer(AggregatedAuctionType);
