import React, { useEffect, useMemo, useState } from 'react';
import { Switch, Tooltip } from 'antd';
import s from './Tariffs.module.scss';
import { observer } from 'mobx-react-lite';
import Slider from 'components/Slider';
import { TariffsCard } from '../TariffsCard/TariffsCard';
import { ModalPayment, ModalWork } from '../modals';
import { useWidth } from 'hooks/useWidth';
import { PageContainer } from 'components/PageContainer';
import { TariffsCardMobile } from '../TariffsCardMobile/TariffsCardMobile';
import { useTariffsStore } from 'stores/TariffsStore/context';
import { TariffPlanType, TariffPricingBillingCycle } from 'entities/tariffs';
import { numberWithSpaces } from 'utils/function';
import { useRootStore } from 'stores/RootStore';
import { AnalyticsEvent } from 'entities/analytics';
import { useUserGetTariffInfo } from 'hooks/usePopupFilterAuth';
import { TariffModel } from 'models/TariffModel';
import InfoTwoIcon from 'components/icons/InfoITwocon/InfoIcon';
import { AuthStep } from 'stores/AuthStore';

const TariffsSlider = observer(
  ({
    onSetPayment,
    onSetWork,
    tab,
    year,
  }: {
    year: boolean;
    onSetPayment: (id: string | number) => void;
    onSetWork: (id: string | number) => void;
    tab: TariffPlanType;
  }) => {
    const { authorized, activeUserTariff } = useUserGetTariffInfo();
    const { authStore, analyticsStore, userStore } = useRootStore();
    const { tariffs } = useTariffsStore();
    const [currentSlide, setCurrentSlide] = React.useState(0);

    const isFirstTrial = useMemo(() => {
      if (userStore.authorized && userStore.user?.tariffType && userStore.user?.tariffType === 'BASIC') {
        return true;
      }
      return false;
    }, [userStore.user, userStore.authorized, userStore.activeUserTariff]);

    useEffect(() => {
      return () => {
        authStore.authStep.change(AuthStep.initial);
        authStore.mode.change('login');
      };
    }, []);

    const handleLoginButtonClick = React.useCallback(
      (id: string | number, tariff_max?: boolean) => {
        if (isFirstTrial && tariff_max) {
          authStore.authStep.change(AuthStep.registration_success);
          authStore.mode.change('register');
          authStore.popupController.open();
        } else if (!authorized) {
          const tariff = tariffs.items.find((item) => item.id === id);
          const isMaxTariff = tariff && tariff.name === 'Максимум';
          authStore.popupController.open();
          authStore.mode.change(isMaxTariff ? 'register' : 'login');
          analyticsStore.trackEvent({ event: AnalyticsEvent.loginButtonClick });
        } else {
          const onSubscribe = tab === TariffPlanType.personal ? onSetPayment : onSetWork;
          onSubscribe(id);
        }
      },
      [authStore.popupController, analyticsStore, tab, authorized, activeUserTariff],
    );

    const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const index = (e.target as HTMLElement).dataset.cardItem;
      const items = document.querySelectorAll(`body div[data-card-item='${index}']`);
      items.forEach((item) => {
        item.classList.add(s.hover);
      });
    };
    const onMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const index = (e.target as HTMLElement).dataset.cardItem;
      const items = document.querySelectorAll(`body div[data-card-item='${index}']`);
      items.forEach((item) => {
        item.classList.remove(s.hover);
      });
    };

    const _items = useMemo(() => {
      const values = tariffs.items.filter((item) => item.plan_type === tab && item.is_trial !== true);
      const tariffTrial = tariffs.items.find((item) => item.is_trial === true);

      if (
        authorized &&
        tariffTrial &&
        activeUserTariff?.tariff_id === tariffTrial.id &&
        tab === TariffPlanType.personal
      ) {
        for (let i = 0; i < values.length; i++) {
          if (values[i].name === 'Максимум') {
            values[i] = tariffTrial;
            break;
          }
        }
      }

      return values.map((tariff) => {
        const typePrice = year ? TariffPricingBillingCycle.year : TariffPricingBillingCycle.month;
        const price =
          tariff.is_free || tariff.is_trial
            ? '0'
            : tariff.pricing_options.find((item) => item.billing_cycle === typePrice)?.price || '0';
        return (
          <TariffsCard
            key={tariff.id}
            id={tariff.id}
            active={tariff.id === activeUserTariff?.tariff_id}
            onActiveSubscribe={handleLoginButtonClick}
            price={numberWithSpaces(parseInt(price)) + ' ₽'}
            name={tariff.name}
            list={[
              tariff?.access_to_lot || false,
              tariff?.access_to_source || false,
              tariff.access_to_cadastral,
              tariff.access_to_documents,
              tariff.smart_search,
              tariff.support_service,
              tariff.number_of_devices,
              <div>
                {tariff.additional_services[0].percent}% от начальной цены лота{' '}
                <div className={s.small}>
                  не более {numberWithSpaces(parseInt(tariff.additional_services[0].max_cost.toString()))} ₽
                </div>{' '}
              </div>,
              <div className={s.sellWrap}>
                {numberWithSpaces(parseInt(tariff.additional_services[1].cost)) + ' ₽'}
                {tariff.additional_services[1].discount > 0 && (
                  <div className={s.sell}>-{parseInt(tariff.additional_services[1].discount.toString())}%</div>
                )}
              </div>,
              tariff.name === 'Максимум' && year ? (
                <div className={s.infoMax}>
                  4 бесплатных показа в год{' '}
                  <Tooltip
                    title={'Доступно к использованию 4 показа в год, далее 5 000 ₽ за показ'}
                    placement="topRight"
                    rootClassName={s.tooltipRoot}
                  >
                    <span className={s.infoIcon}>
                      <InfoTwoIcon />
                    </span>
                  </Tooltip>
                </div>
              ) : (
                <div className={s.sellWrap}>
                  {numberWithSpaces(parseInt(tariff.additional_services[2].cost)) + ' ₽'}
                  {tariff.additional_services[2].discount > 0 && (
                    <div className={s.sell}>-{parseInt(tariff.additional_services[2].discount.toString())}%</div>
                  )}
                </div>
              ),
              ,
            ]}
            info={
              tariff.image
                ? {
                    text: tariff.description || '',
                    image: tariff.image,
                  }
                : undefined
            }
            auth={authorized}
            authText={
              tariff.name === 'Профи Макс'
                ? 'Оставить заявку'
                : (isFirstTrial && tariff.name === 'Максимум') || (!authorized && tariff.name === 'Максимум')
                  ? '3 дня за 1 рубль'
                  : undefined
            }
            onMouseLeave={onMouseLeave}
            onMouseMove={onMouseMove}
          />
        );
      });
    }, [tab, currentSlide, year, activeUserTariff, authorized, activeUserTariff]);

    useEffect(() => {
      setCurrentSlide(0);
    }, [tab]);

    return (
      <div className={s.tariffs__wrap}>
        <div className={s.tariff__labes}>
          <div className={s.tariff__labels__offset}></div>
          <div
            className={s.tariff__labels__text}
            data-card-item="0"
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
          >
            Просмотр адреса объекта{' '}
          </div>
          <div
            className={s.tariff__labels__text}
            data-card-item="1"
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
          >
            Прямая ссылка на ЭТП{' '}
          </div>
          <div
            className={s.tariff__labels__text}
            data-card-item="2"
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
          >
            Кадастровый номер{' '}
          </div>
          <div
            className={s.tariff__labels__text}
            data-card-item="3"
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
          >
            Документы объекта{' '}
          </div>
          <div
            className={s.tariff__labels__text}
            data-card-item="4"
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
          >
            Умный поиск{' '}
          </div>
          <div
            className={s.tariff__labels__text}
            data-card-item="5"
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
          >
            Приоритетная служба поддержки{' '}
          </div>
          <div
            className={s.tariff__labels__text}
            data-card-item="6"
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
          >
            Количество устройств{' '}
          </div>
          <div
            className={s.tariff__labels__text}
            data-card-item="7"
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
          >
            Сопровождение сделки{' '}
          </div>
          <div
            className={s.tariff__labels__text}
            data-card-item="8"
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
          >
            Юридический аудит{' '}
          </div>
          <div
            className={s.tariff__labels__text}
            data-card-item="9"
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
          >
            Онлайн-показ{' '}
          </div>
        </div>
        <div className={s.sliderWrap}>
          <Slider
            watchOverflow
            slidesPerView={'auto'}
            spaceBetween={20}
            slides={_items}
            onSlideChange={(sw) => {
              setCurrentSlide(sw.activeIndex);
            }}
            slideClass={s.slide}
            className={s.tariff_list}
            slidesOffsetAfter={20}
          />
        </div>
      </div>
    );
  },
);

const TariffsSliderMobile = observer(
  ({
    year,
    onSetPayment,
    onSetWork,
    tab,
  }: {
    year: boolean;
    onSetPayment: (id: string | number) => void;
    onSetWork: (id: string | number) => void;
    tab: TariffPlanType;
  }) => {
    const { authorized, activeUserTariff } = useUserGetTariffInfo();
    const { authStore, analyticsStore, userStore } = useRootStore();
    const { tariffs } = useTariffsStore();
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const isFirstTrial = useMemo(() => {
      if (userStore.authorized && userStore.user?.tariffType && userStore.user?.tariffType === 'BASIC') {
        return true;
      }

      return false;
    }, [userStore.user, userStore.authorized, userStore.activeUserTariff]);

    useEffect(() => {
      return () => {
        authStore.authStep.change(AuthStep.initial);
        authStore.mode.change('login');
      };
    }, []);

    const handleLoginButtonClick = React.useCallback(
      (id: string | number, tariff_max?: boolean) => {
        if (isFirstTrial && tariff_max) {
          authStore.authStep.change(AuthStep.registration_success);
          authStore.mode.change('register');
          authStore.popupController.open();
        } else if (!authorized) {
          authStore.popupController.open();
          authStore.mode.change('login');
          analyticsStore.trackEvent({ event: AnalyticsEvent.loginButtonClick });
        } else {
          const onSubscribe = tab === TariffPlanType.personal ? onSetPayment : onSetWork;
          onSubscribe(id);
        }
      },
      [authStore.popupController, analyticsStore, tab, authorized, activeUserTariff],
    );

    useEffect(() => {
      setCurrentSlide(0);
    }, [tab]);

    const _items = useMemo(() => {
      const values = tariffs.items.filter((item) => item.plan_type === tab && item.is_trial !== true);
      const tariffTrial = tariffs.items.find((item) => item.is_trial === true);
      if (
        authorized &&
        tariffTrial &&
        activeUserTariff?.tariff_id === tariffTrial.id &&
        tab === TariffPlanType.personal
      ) {
        for (let i = 0; i < values.length; i++) {
          if (values[i].name === 'Максимум') {
            values[i] = tariffTrial;
            break;
          }
        }
      }

      return values.map((tariff) => {
        const typePrice = year ? TariffPricingBillingCycle.year : TariffPricingBillingCycle.month;
        const price =
          tariff.is_free || tariff.is_trial
            ? '0'
            : tariff.pricing_options.find((item) => item.billing_cycle === typePrice)?.price || '0';

        return (
          <TariffsCardMobile
            id={tariff.id}
            active={tariff.id === activeUserTariff?.tariff_id}
            onActiveSubscribe={handleLoginButtonClick}
            price={numberWithSpaces(parseInt(price)) + ' ₽'}
            name={tariff.name}
            list={[
              tariff?.access_to_lot || false,
              tariff?.access_to_source || false,
              tariff.access_to_cadastral,
              tariff.access_to_documents,
              tariff.smart_search,
              tariff.support_service,
              tariff.number_of_devices,
              <div>{tariff.additional_services[0].percent}%*</div>,
              <div className={s.sellWrap + ' ' + s.flex}>
                {numberWithSpaces(parseInt(tariff.additional_services[1].cost)) + ' ₽'}{' '}
                {tariff.additional_services[1].discount > 0 && (
                  <div className={s.sell + ' ' + s.relative}>
                    -{parseInt(tariff.additional_services[1].discount.toString())}%
                  </div>
                )}{' '}
              </div>,
              <div className={s.sellWrap + ' ' + s.flex}>
                {numberWithSpaces(parseInt(tariff.additional_services[2].cost)) + ' ₽'}
                {tariff.name === 'Максимум' && year ? ' **' : ''}
                {tariff.additional_services[2].discount > 0 && tariff.name !== 'Максимум' && !year && (
                  <div className={s.sell + ' ' + s.relative}>
                    -{parseInt(tariff.additional_services[2].discount.toString())}%
                  </div>
                )}{' '}
              </div>,
            ]}
            info={
              tariff.image
                ? {
                    text: tariff.description || '',
                    image: tariff.image,
                  }
                : undefined
            }
            auth={authorized}
            authText={
              (isFirstTrial && tariff.name === 'Максимум') || (!authorized && tariff.name === 'Максимум')
                ? '3 дня за 1 рубль'
                : undefined
            }
          />
        );
      });
    }, [tab, currentSlide, year, activeUserTariff, authorized]);

    return (
      <div>
        <div className={s.sliderWrapMobile}>
          <Slider
            watchOverflow
            slidesPerView={'auto'}
            spaceBetween={20}
            slides={_items}
            onSlideChange={(sw) => {
              setCurrentSlide(sw.activeIndex);
            }}
            slideClass={s.slide_mobile}
            className={s.tariff_list}
            slidesOffsetAfter={32}
          />
        </div>
        <p className={s.slider__info}>
          *1% от начальной цены лота, но не более <br /> 100 тыс. рублей
        </p>
        <p className={s.slider__info}>** Доступно к использованию 4 бесплатных показа в год, далее 5 000 ₽ за показ</p>
      </div>
    );
  },
);

export const Tariffs = observer(() => {
  const { tariffs } = useTariffsStore();

  const { width = window.innerWidth } = useWidth();
  const [tab, setTab] = useState<TariffPlanType>(TariffPlanType.personal);
  const [year, setYear] = useState(false);

  const [modalDataPayment, setModalDataPayment] = useState<TariffModel | null>(null);
  const [modalDataWork, setModalDataWork] = useState<TariffModel | null>(null);

  const onClosePayment = () => setModalDataPayment(null);
  const onCloseWork = () => setModalDataWork(null);

  const onSetPayment = (id: string | number) => {
    const tariff = tariffs.items.find((item) => item.id === id);
    tariff && setModalDataPayment(tariff);
  };

  const onSetWork = (id: string | number) => {
    const tariff = tariffs.items.find((item) => item.id === id);
    tariff && setModalDataWork(tariff);
  };

  return (
    <div className={s.section}>
      <PageContainer withoutBottomPadding withoutTopPadding className={s.container}>
        <div className={s.tabs}>
          <button
            className={s.tab + ' ' + (tab === TariffPlanType.personal ? s.active : '')}
            onClick={() => setTab(TariffPlanType.personal)}
          >
            Частным лицам
          </button>
          <button
            className={s.tab + ' ' + (tab === TariffPlanType.corporate ? s.active : '')}
            onClick={() => setTab(TariffPlanType.corporate)}
          >
            Корпоративным клиентам
          </button>
        </div>

        <div className={s.swith}>
          <span className={s.swith__label}>Месяц</span>
          <Switch
            value={year}
            onChange={() => {
              setYear((prev) => !prev);
            }}
          />
          <span className={s.swith__label}>
            Год <span className={s.chip}>Выгоднее на 17%</span>
          </span>
        </div>
      </PageContainer>
      {width > 820 ? (
        <PageContainer withoutBottomPadding withoutTopPadding className={s.container}>
          <TariffsSlider onSetPayment={onSetPayment} onSetWork={onSetWork} tab={tab} year={year} />
        </PageContainer>
      ) : (
        <TariffsSliderMobile onSetPayment={onSetPayment} onSetWork={onSetWork} tab={tab} year={year} />
      )}

      <ModalPayment data={modalDataPayment} onClose={onClosePayment} isYear={year} />
      <ModalWork data={modalDataWork} onClose={onCloseWork} isYear={year} />
    </div>
  );
});
