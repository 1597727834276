import { IServicesData, IAdditionalServiceData, IAdditionalServicePriceData } from 'entities/services/services';

export class AdditionaServiceModel implements IAdditionalServiceData {
  readonly id;
  readonly cost;
  readonly name;
  readonly ordering;
  readonly image;
  readonly slug;

  constructor(params: IAdditionalServiceData) {
    this.id = params.id;
    this.cost = params.cost;
    this.name = params.name;
    this.ordering = params.ordering;
    this.image = params.image;
    this.slug = params.slug;
  }

  static fromJson(raw: IAdditionalServiceData): IAdditionalServiceData {
    return {
      id: raw.id,
      cost: raw.cost,
      name: raw.name,
      ordering: raw.ordering,
      image: raw.image,
      slug: raw.slug ? raw.slug : 'unset',
    };
  }
}

export class AdditionaServicePriceModel implements IAdditionalServicePriceData {
  readonly id;
  readonly tariff_id;
  readonly tariff_name;
  readonly cost;
  readonly discount;

  constructor(params: IAdditionalServicePriceData) {
    this.id = params.id;
    this.cost = params.cost;
    this.tariff_id = params.tariff_id;
    this.tariff_name = params.tariff_name;
    this.discount = params.discount;
  }

  static fromJson(raw: IAdditionalServicePriceData): IAdditionalServicePriceData {
    return {
      id: raw.id,
      cost: raw.cost,
      tariff_id: raw.tariff_id,
      tariff_name: raw.tariff_name,
      discount: raw.discount,
    };
  }
}

export class ServiceModel implements IServicesData {
  readonly id: number;
  readonly cost: string;
  readonly request_date: string;
  readonly status;
  readonly user: number;
  readonly service: number;
  readonly user_tariff: number;
  readonly lot: number;
  readonly slug: string;

  constructor(params: IServicesData) {
    this.id = params.id;
    this.cost = params.cost;
    this.request_date = params.request_date;
    this.status = params.status;
    this.user = params.user;
    this.service = params.service;
    this.user_tariff = params.user_tariff;
    this.lot = params.lot;
    this.slug = params.slug;
  }

  static fromJson(raw: IServicesData): IServicesData {
    return {
      id: raw.id,
      cost: raw.cost,
      request_date: raw.request_date,
      status: raw.status,
      user: raw.user,
      service: raw.service,
      user_tariff: raw.user_tariff,
      lot: raw.lot,
      slug: raw.slug,
    };
  }
}
