import { Input } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { BaseInput } from 'components/BaseInput';
import { useLotListStore } from 'stores/LotListCatalogStore/context';

import s from './CadastralNumber.module.scss';

type CadastralNumberProps = {
  className?: string;
};

const CadastralNumber = ({}: CadastralNumberProps): React.ReactElement<CadastralNumberProps> => {
  const { filters } = useLotListStore();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/[^0-9:]/g, '');

    if (e.nativeEvent instanceof InputEvent && e.nativeEvent.inputType !== 'deleteContentBackward') {
      if (value.length === 2 && /^\d{2}$/.test(value)) {
        value = value.slice(0, 2) + ':' + value.slice(2);
      }
      if (value.length === 5 && /^\d{2}:\d{2}$/.test(value)) {
        value = value.slice(0, 5) + ':' + value.slice(5);
      }
      if (value.length === 12 && /^\d{2}:\d{2}:\d{6}$/.test(value)) {
        value = value.slice(0, 12) + ':' + value.slice(12);
      }
      if (value.length >= 14 && !value.includes(':')) {
        value = value.slice(0, 14);
        value = value.replace(/^(\d{2})(\d{2})(\d{6})(\d{2})$/, '$1:$2:$3:$4');
      }
    }

    e.target.value = value;
    filters.cadastralNumber.change(value);
  };

  return (
    <BaseInput title="Кадастровый номер">
      <Input
        className={s['input']}
        placeholder={'Не выбрано'}
        value={filters.cadastralNumber.value}
        onChange={handleChange}
        maxLength={17}
      />
    </BaseInput>
  );
};

export default observer(CadastralNumber);