import { Modal, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import CloseRoundIcon from 'components/icons/CloseRound';
import LogoIcon from 'components/icons/Logo';
import { AuthStep } from 'stores/AuthStore';
import { useRootStore } from 'stores/RootStore';

import ConfirmStep from './ConfirmStep/ConfirmStep';
import InitialStep from './InitialStep/InitialStep';
import SuccessStep from './SuccessStep/SuccessStep';
import { useSwipeable } from 'react-swipeable';
import s from './AuthPopup.module.scss';
import { RegistrationSuccessStep } from './RegistrationSuccessStep/RegistrationSuccessStep';
import CloseCircledIcon from 'components/icons/CloseCircle';

const factoryContentModal: { [key in AuthStep]: React.ReactNode } = {
  [AuthStep.initial]: <InitialStep />,
  [AuthStep.confirm]: <ConfirmStep />,
  [AuthStep.success]: <SuccessStep />,
  [AuthStep.registration_success]: <RegistrationSuccessStep />,
};

const AuthPopup: React.FC = () => {
  const { authStore } = useRootStore();
  const { authStep, popupController } = authStore;
  const isRegistration = authStep.value === AuthStep.registration_success;
  const handlers = useSwipeable({
    onSwipedDown: () => {
      popupController.close();
    },
    delta: { down: 100 },
  });

  return (
    <Modal
      classNames={{ body: isRegistration ? s.popup__body__reg : s.popup__body, content: s.popup__card }}
      className={isRegistration ? s.popup__reg : s.popup}
      open={popupController.value}
      footer={false}
      closeIcon={
        isRegistration ? (
          <CloseCircledIcon className={s.popup__close__reg} />
        ) : (
          <CloseRoundIcon className={s.popup__close} />
        )
      }
      width={447}
      onCancel={popupController.close}
      centered
    >
      <div {...handlers}>
        <Space className={s.popup__content} direction="vertical" size={24}>
          {!isRegistration && (
            <LogoIcon className={authStep.value === AuthStep.success ? s.popup__logo__success : s.popup__logo} />
          )}

          {factoryContentModal[authStep.value]}
        </Space>
      </div>
    </Modal>
  );
};

export default observer(AuthPopup);
