import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { BaseInput } from 'components/BaseInput';
import { RangeFilter } from 'components/RangeFilter';
import { useLotListStore } from 'stores/LotListCatalogStore/context';
import {
  FLOOR_OPTIONS_DEFAULT,
  FLOOR_OPTIONS_LABELS,
} from 'stores/LotListCatalogStore/LotListCatalogFiltersModel/config';
import { Checkbox } from 'antd';
import { FloorOptions } from 'entities/lotFilters';
import s from './Floor.module.scss';
import { usePermission } from 'hooks/usePopupFilterAuth';

type FloorProps = {
  className?: string;
};

const Floor = ({ className }: FloorProps): React.ReactElement<FloorProps> => {
  const { filters } = useLotListStore();
  const { onShowPermissionPopup, isPermissionBlocked } = usePermission();

  return (
    <div className={s.floor}>
      <BaseInput className={className} title="Этаж">
        <RangeFilter
          from={filters.floor.from.value}
          to={filters.floor.to.value}
          valueMin={filters.floor.valueMin.value}
          valueMax={filters.floor.valueMax.value}
          onChangeMin={filters.floor.valueMin.change}
          onChangeMax={filters.floor.valueMax.change}
          placeholderSufix={{
            from: 'от ' + (filters.floor.from.value || 0),
            to: 'до ' + (filters.floor.to.value || 0),
          }}
          offFilter={onShowPermissionPopup}
        />
      </BaseInput>
      <div className={s.options} onClick={onShowPermissionPopup}>
        {Object.keys(FLOOR_OPTIONS_DEFAULT).map((key) => {
          const optionKey = key as FloorOptions;
          return (
            <Checkbox
              key={key}
              disabled={isPermissionBlocked}
              onChange={() => {
                filters.floorOptions.change({
                  ...filters.floorOptions.value,
                  [optionKey]: !filters.floorOptions.value[optionKey],
                });
              }}
              checked={filters.floorOptions.value[optionKey]}
              className={s.checkbox}
            >
              {FLOOR_OPTIONS_LABELS[optionKey]}
            </Checkbox>
          );
        })}
      </div>
    </div>
  );
};

export default observer(Floor);
