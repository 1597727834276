import React, { ReactNode } from 'react';
import s from './CardProcess.module.scss';

export const CardProcess = ({
  image,
  postion,
  children,
  className = '',
}: {
  image: string;
  postion?: number;
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div className={s.card + ' ' + className}>
      {postion && <span className={s.position}>{postion}</span>}
      <img src={image} className={className !== '' ? s.image : s.imageDefault} alt="" />
      <div className={s.content}>{children}</div>
    </div>
  );
};
