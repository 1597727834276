import React from 'react';
import { CardProcess } from '../CardProcess/CardProcess';
import s from './GridProcess.module.scss';
import g1Img from 'assets/escort/g1.png';
import g2Img from 'assets/escort/g2.png';
import g3Img from 'assets/escort/g3.png';
import g4Img from 'assets/escort/g4.png';
import g5Img from 'assets/escort/g5.png';
import g6Img from 'assets/escort/g6.png';
import g7Img from 'assets/escort/g7.png';
import g8Img from 'assets/escort/g8.png';
import gridImg1 from 'assets/escort/gg1.png';
import gridImg2 from 'assets/escort/gg2.png';
import gridImg3 from 'assets/escort/gg3.png';
import { useWidth } from 'hooks/useWidth';

export const GridProcess = () => {
  return (
    <div className={s.gridProcess}>
      <div className={s.paperCard + ' ' + s.gridProcessOne}>
        <img src={g1Img} alt="" />
        <h3 className={s.title}>Юридический аудит документов</h3>
        <ul className={s.desc}>
          <li>Агрегированная информация по лоту</li>
          <li>Проверка обременений</li>
          <li>Инструкция по участию в торгах</li>
          <li>Информация о возможных рисках</li>
        </ul>
      </div>
      <div className={s.paperCard + ' ' + s.gridProcessTwo}>
        <img src={g2Img} alt="" />
        <div className={s.gridContentTwo}>
          <h3 className={s.title}>Заключение о рисках на основе документов лота и торгов</h3>
          <p className={s.desc}>Тщательно оценим возможные риски и укажем их в заключении</p>
        </div>
      </div>
      <div className={s.paperCard + ' ' + s.gridProcessThree}>
        <img src={g3Img} alt="" />
        <div className={s.gridContentThree}>
          <h3 className={s.title}>Мы берем на себя все организационные моменты для участия в торгах</h3>
          <p className={s.desc}>Освобождаем вас от необходимости проходить аккредитацию на площадке или выпускать ЭП</p>
        </div>
      </div>

      <div className={s.paperCard + ' ' + s.gridProcessFour}>
        <img src={g4Img} alt="" />
        <h3 className={s.title}>Подготовим все необходимые документы для участия в торгах</h3>
      </div>
      <div className={s.paperCard + ' ' + s.gridProcessFive}>
        <img src={g5Img} alt="" />
        <h3 className={s.title}>Отправляем заявку на участие в торгах</h3>
      </div>
      <div className={s.paperCard + ' ' + s.gridProcessSix}>
        <img src={g6Img} alt="" />
        <h3 className={s.title}>Принимаем участие в торгах</h3>
      </div>

      <div className={s.paperCard + ' ' + s.gridProcessSeven}>
        <img src={g7Img} alt="" />
        <div className={s.gridContentSeven}>
          <h3 className={s.title}>В случае победы в торгах</h3>
          <p className={s.desc}>
            Предоставляем всю информацию, необходимую для корректного и своевременного заключения договора купли-продажи
          </p>
        </div>
      </div>
      <div className={s.paperCard + ' ' + s.gridProcessEight}>
        <img src={g8Img} alt="" />
        <div className={s.gridContentEight}>
          <h3 className={s.title}>В случае проигрыша или отказа от участия в процедуре торгов</h3>
          <p className={s.desc}>Мы подаем надлежащее заявление для возврата задатка</p>
        </div>
      </div>
    </div>
  );
};

export const GridProcessMobile = () => {
  return (
    <div className={s.gridProcessMobile}>
      <CardProcess image={gridImg1} postion={1} className={s.gridMobileOne}>
        <div>
          <h3>Юридический аудит документов</h3>
          <ul>
            <li>Агрегированная информация по лоту</li>
            <li>Проверка обременений</li>
            <li>Инструкция по участию в торгах</li>
            <li>Информация о возможных рисках</li>
          </ul>
        </div>
        <div>
          <h3>
            Заключение о рисках на основе <br />
            документов лота и торгов
          </h3>
          <p>
            Тщательно оценим возможные риски <br />и укажем их в заключении
          </p>
        </div>
        <h3>Мы берем на себя все организационные моменты для участия в торгах</h3>
      </CardProcess>
      <CardProcess image={gridImg2} postion={2}>
        <ul className={s.paddingList}>
          <li>
            <h3>
              Подготовим все необходимые <br />
              документы для участия в торгах
            </h3>
          </li>
        </ul>
        <ul className={s.paddingList}>
          <li>
            <h3>
              Отправляем заявку на участие <br />в торгах
            </h3>
          </li>
        </ul>
        <ul className={s.paddingList}>
          <li>
            <h3>Принимаем участие в торгах</h3>
          </li>
        </ul>
      </CardProcess>
      <CardProcess image={gridImg3} postion={3}>
        <div>
          <h3>В случае победы в торгах</h3>
          <p>
            Предоставляем всю информацию, <br />
            необходимую для корректного <br />и своевременного заключения договора <br />
            купли-продажи
          </p>
        </div>
        <div>
          <h3>
            В случае проигрыша или отказа <br />
            от участия в процедуре торгов
          </h3>
          <p>
            Мы подаем надлежащее заявление <br />
            для возврата задатка
          </p>
        </div>
      </CardProcess>
    </div>
  );
};

export const GridProcessSection = () => {
  const { width = window.innerWidth } = useWidth();
  return width <= 928 ? <GridProcessMobile /> : <GridProcess />;
};
