import React, { useContext, useEffect, useState } from 'react';
import { ReactNode } from 'react';
import { NotificationModal } from './NotificationModal';
type NotificationModalCtxBody = {
  title: string;
  desc: string;
  img: string;
  label?: string;
};

type NotificationModalCtxProps = {
  body: NotificationModalCtxBody;
  changeNotificationBody: (value: { title: string; desc: string; img: string; label?: string }) => void;
  open: boolean;
  onChangeNotificationModalOpen: (value: boolean) => void;
};

export const NotificationModalContext = React.createContext<NotificationModalCtxProps>({
  body: {
    title: '',
    desc: '',
    img: '',
    label: undefined,
  },
  open: false,
  onChangeNotificationModalOpen: (value: boolean) => void 0,
  changeNotificationBody: (value: NotificationModalCtxBody) => void 0,
});

export const NotificationModalProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [body, setBody] = useState<NotificationModalCtxBody>({
    title: '',
    desc: '',
    img: '',
    label: undefined,
  });
  return (
    <NotificationModalContext.Provider
      value={{
        open,
        onChangeNotificationModalOpen: setOpen,
        body,
        changeNotificationBody: setBody,
      }}
    >
      {children}
      <NotificationModal />
    </NotificationModalContext.Provider>
  );
};

export const useNotificationModal = (initBody: NotificationModalCtxBody) => {
  const ctx = useContext(NotificationModalContext);
  useEffect(() => {
    ctx.changeNotificationBody(initBody);
  }, []);

  return {
    ...ctx,
  };
};
