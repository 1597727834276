import { CollapsedStatic } from 'components/Сollapsed/Сollapsed';
import React from 'react';
import s from './Faq.module.scss';
import CloseIcon from 'components/icons/CloseIcon/CloseIcon';

export const Faq = () => {
  return (
    <div className={s.collapsed}>
      <CollapsedStatic
        title="В каких случаях требуется юридический аудит недвижимости?"
        icon={<CloseIcon className={s.iconClose} />}
      >
        <div className={s.content}>
          <p>
            Юридический аудит очень важно провести перед участием в торгах, чтобы правильно оценить все возможные риски
            и обеспечить правовую чистоту сделки. Особое внимание стоит уделить объектам банкротного имущества, по ним
            риски наиболее существенны. Эти объекты часто имеют сложную юридическую историю, которая может включать в
            себя аресты, залоги и претензии третьих лиц
          </p>
        </div>
      </CollapsedStatic>
      <CollapsedStatic
        title="Какие объекты проверяются в рамках юридического аудита?"
        icon={<CloseIcon className={s.iconClose} />}
      >
        <div className={s.content}>
          <p>
            Мы проводим юридический аудит для всех видов объектов. Особенно важно проводить данный анализ для процедуры
            реализации имущества банкрота
          </p>
        </div>
      </CollapsedStatic>
      <CollapsedStatic
        title="Сколько длится подготовка заключения от юристов?"
        icon={<CloseIcon className={s.iconClose} />}
      >
        <div className={s.content}>
          <p>
            Процесс подготовки заключения занимает от 2 до 5 дней. Сроки зависят от типа объекта и документов, доступных
            для проверки
          </p>
        </div>
      </CollapsedStatic>
    </div>
  );
};

export const FaqEscort = () => {
  return (
    <div className={s.collapsed}>
      <CollapsedStatic
        title="Как узнать стоимость для выбранного объекта?"
        icon={<CloseIcon className={s.iconClose} />}
      >
        <div className={s.content}>
          <p>
            Стоимость услуги сопровождения сделки рассчитывается от начальной цены лота и составляет 1%, но не менее 39
            000 р. и не более 100 000 р. Оставьте заявку, и наш менеджер свяжется с вами, чтобы уточнить детали по
            объекту
          </p>
        </div>
      </CollapsedStatic>
      <CollapsedStatic
        title="Какие документы нужны для представления в торгах?"
        icon={<CloseIcon className={s.iconClose} />}
      >
        <div className={s.content}>
          <p>
            Мы возьмем на себя все задачи, связанные с участием в торгах. Наш менеджер соберет пакет документов,
            объяснит, на какой счет переводить задаток, составит заявку, которую гарантированно примут. Он будет держать
            вас в курсе всех событий и информировать о текущем статусе вашего участия в торговой процедуре
          </p>
        </div>
      </CollapsedStatic>
      <CollapsedStatic
        title="Гарантируете ли вы победу в торговой процедуре?"
        icon={<CloseIcon className={s.iconClose} />}
      >
        <div className={s.content}>
          <p>
            Мы не гарантируем победу в торговой процедуре, но гарантируем корректность действий на всех этапах. Наш
            менеджер согласует с вами стратегию по участию в торговой процедуре
          </p>
        </div>
      </CollapsedStatic>
    </div>
  );
};

export const FaqOnline = () => {
  return (
    <div className={s.collapsed}>
      <CollapsedStatic title="Как отменить или перенести онлайн-показ?" icon={<CloseIcon className={s.iconClose} />}>
        <div className={s.content}>
          <p>
            Для отмены или переноса показа необходимо уведомить менеджера минимум за 24 часа до начала онлайн-показа
          </p>
        </div>
      </CollapsedStatic>
      <CollapsedStatic title="Для каких объектов доступен онлайн-показ?" icon={<CloseIcon className={s.iconClose} />}>
        <div className={s.content}>
          <p>
            По ряду торговых процедур проведение осмотра невозможно по решению организатора процедуры. По многим
            процедурам доступ на просмотр объекта разрешается не чаще 1-2 раз в неделю по решению организатора
            процедуры. Возможность проведения онлайн-просмотра выбранного объекта вам уточнит менеджер. Оставьте заявку,
            и мы проконсультируем вас, а также согласуем удобное время для показа
          </p>
        </div>
      </CollapsedStatic>
      <CollapsedStatic
        title="Что делать, если я пропустил показ или не смог быть на связи в назначенное время?"
        icon={<CloseIcon className={s.iconClose} />}
      >
        <div className={s.content}>
          <p>В этом случае мы проведем фото- и видеосъемку объекта и предоставим вам материалы на облачном хранилище</p>
        </div>
      </CollapsedStatic>
    </div>
  );
};
