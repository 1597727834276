import * as React from 'react';
import { BaseIconProps } from '../commonProps';

const InfoIcon = (props: BaseIconProps): React.ReactElement<BaseIconProps> => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_162_1385)">
        <path
          d="M30 55.5H30.0006C36.7612 55.4926 43.2427 52.8037 48.0232 48.0232C52.8037 43.2427 55.4926 36.7612 55.5 30.0006V30C55.5 24.9566 54.0045 20.0264 51.2025 15.833C48.4005 11.6395 44.418 8.37111 39.7584 6.44107C35.0989 4.51104 29.9717 4.00605 25.0252 4.98998C20.0787 5.9739 15.535 8.40254 11.9688 11.9688C8.40255 15.535 5.97391 20.0787 4.98999 25.0252C4.00607 29.9717 4.51105 35.0989 6.44109 39.7584C8.37112 44.4179 11.6395 48.4005 15.833 51.2025C20.0264 54.0045 24.9566 55.5 30 55.5ZM13.6107 5.47165C18.4619 2.23019 24.1653 0.500053 29.9997 0.5C37.8211 0.508532 45.3196 3.61932 50.8502 9.14985C56.3808 14.6804 59.4916 22.1791 59.5 30.0005C59.4999 35.8349 57.7698 41.5382 54.5284 46.3893C51.2869 51.2406 46.6796 55.0217 41.2892 57.2544C35.8988 59.4872 29.9673 60.0714 24.2449 58.9332C18.5224 57.7949 13.266 54.9853 9.14036 50.8597C5.01471 46.734 2.20511 41.4776 1.06685 35.7552C-0.0714163 30.0327 0.512782 24.1013 2.74557 18.7108C4.97835 13.3204 8.75944 8.71315 13.6107 5.47165Z"
          fill="currentColor"
          stroke="#F3F4F7"
        />
        <path
          d="M28.5858 13.5858C28.9609 13.2107 29.4696 13 30 13C30.5304 13 31.0391 13.2107 31.4142 13.5858C31.7893 13.9609 32 14.4696 32 15V35C32 35.5304 31.7893 36.0391 31.4142 36.4142C31.0391 36.7893 30.5304 37 30 37C29.4696 37 28.9609 36.7893 28.5858 36.4142C28.2107 36.0391 28 35.5304 28 35V15C28 14.4696 28.2107 13.9609 28.5858 13.5858Z"
          fill="currentColor"
          stroke="#F3F4F7"
        />
        <path
          d="M30 43C31.1046 43 32 43.8954 32 45C32 46.1046 31.1046 47 30 47C28.8954 47 28 46.1046 28 45C28 43.8954 28.8954 43 30 43Z"
          fill="currentColor"
          stroke="#F3F4F7"
        />
      </g>
      <defs>
        <clipPath id="clip0_162_1385">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InfoIcon;
