import React from 'react';
import s from './CardInteresting.module.scss';
import { Button } from 'components/Button';
import ArrowTopRight from 'components/icons/ArrowTopRight/ArrowTopRight';

export const CardInteresting = ({
  className = '',
  title,
  description,
  image,
  onClick,
}: {
  title: string;
  description: string;
  className?: string;
  image: string;
  onClick?: () => void;
}) => {
  return (
    <div className={s.card + ' ' + className} onClick={onClick}>
      <ArrowTopRight className={s.arrow} />
      <div className={s.image}>
        <img src={image} alt="" />
      </div>
      <div className={s.contentWrap}>
        <div className={s.content}>
          <h4 className={s.title}>{title}</h4>
          <p className={s.text}>{description}</p>
        </div>
        {onClick && (
          <Button className={s.btn} onClick={onClick}>
            Подробнее
          </Button>
        )}
      </div>
    </div>
  );
};
