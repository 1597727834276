import React from 'react';
import {
  CircleProgressIconFour,
  CircleProgressIconOne,
  CircleProgressIconThee,
  CircleProgressIconTwo,
} from 'components/icons/CircleProgressIcon/CircleProgressIcon';
import { CardInfo } from '../../../../components/CardInfo/CardInfo';
import s from './Reports.module.scss';
import { useWidth } from 'hooks/useWidth';

export const Reports = () => {
  const { width = window.innerWidth } = useWidth();
  return (
    <div className={s.reports}>
      {width >= 996 ? (
        <>
          <div className={s.reportsHeader}>
            <CircleProgressIconOne />
            <div className={s.reportsSp} />
            <CircleProgressIconTwo />
            <div className={s.reportsSp} />
            <CircleProgressIconThee />
            <div className={s.reportsSp} />
            <CircleProgressIconFour />
          </div>
          <div className={s.reportsGrid}>
            <CardInfo
              className={s.reportsGridItemOne}
              title="Агрегированная <br />информация по лоту"
              desc="Ключевая информация об объекте <br />в одном документе"
              type="text"
            />
            <CardInfo
              className={s.reportsGridItemTwo}
              title="Проверка обременений"
              desc="Проверка наличия ограничений <br />и обременений на объекте торгов"
              type="text"
            />
            <CardInfo
              className={s.reportsGridItemThree}
              title="Инструкция по участию <br />в торгах"
              desc="Подробная инструкция по шагам: <br />что нужно сделать, чтобы участвовать <br />в торгах"
              type="text"
            />
            <CardInfo
              className={s.reportsGridItemFour}
              title="Информация <br />о возможных рисках"
              desc="Информация о потенциальных <br />проблемах, связанных с участием <br />в торговой процедуре по объекту"
              type="text"
            />
          </div>
        </>
      ) : (
        <>
          <div className={s.row}>
            <CircleProgressIconOne />
            <CardInfo
              title="Агрегированная <br />информация по лоту"
              desc="Ключевая информация об объекте <br />в одном документе"
              type="text"
            />
            <div className={s.spVertical + ' ' + s.spVerticalBottom} />
          </div>
          <div className={s.row}>
            <CircleProgressIconTwo />
            <CardInfo
              title="Проверка обременений"
              desc="Проверка наличия ограничений <br />и обременений на объекте торгов"
              type="text"
            />
            <div className={s.spVertical + ' ' + s.spVerticalTop} />
            <div className={s.spVertical + ' ' + s.spVerticalBottom} />
          </div>
          <div className={s.row}>
            <CircleProgressIconThee />
            <CardInfo
              title="Инструкция по участию <br />в торгах"
              desc="Подробная инструкция по шагам: <br />что нужно сделать, чтобы участвовать <br />в торгах"
              type="text"
            />
            <div className={s.spVertical + ' ' + s.spVerticalTop} />
            <div className={s.spVertical + ' ' + s.spVerticalBottom} />
          </div>
          <div className={s.row}>
            <CircleProgressIconFour />
            <CardInfo
              title="Информация <br />о возможных рисках"
              desc="Информация о потенциальных <br />проблемах, связанных с участием <br />в торговой процедуре по объекту"
              type="text"
            />
            <div className={s.spVertical + ' ' + s.spVerticalTop} />
          </div>
        </>
      )}
    </div>
  );
};
