import { Divider, Space, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { DigitalPlatform } from 'components/DigitalPlatform';
import { ElementId } from 'config/ids';
import { useLotPageMultiStore } from 'stores/LotPageStore';

import Header from './Header';
import InfoBlock from './InfoBlock';

import s from './MainInfo.module.scss';
import { isLotModel } from 'utils/lot';
import { useRootStore } from 'stores/RootStore';
import { IconText } from 'components/IconText/IconText';
import { useUserGetTariffInfo } from 'hooks/usePopupFilterAuth';
import { IconTextAuth } from 'components/IconTextAuth/IconTextAuth';

const MainInfo = ({ showPrintPdf = false }: { showPrintPdf?: boolean }) => {
  const { userStore } = useRootStore();
  const { lot: lotModel } = useLotPageMultiStore();
  const lot = lotModel.value;
  const { auctionType, priceMinFormatted, source, timelineModel, city, lotUrl, isDowngradeAuction, id } = lot;
  const tariffUser = useUserGetTariffInfo();

  return (
    <div className={s['main-info']} id={ElementId.lotPageMainInfo}>
      <Header className={s['main-info__header']} showPrintPdf={showPrintPdf} />
      <Space direction="vertical" size="middle" className={s['main-info__body']}>
        <InfoBlock title="Номер лота" text={'№' + id} />
        <InfoBlock title="Вид торгов" text={auctionType} />
        <InfoBlock
          title="Начальная цена"
          text={
            <Space align="start" size={10}>
              <span>{priceMinFormatted}</span>
              <Tag bordered={false} className={s['main-info__price-tag']} color="blue">
                С учетом НДС
              </Tag>
            </Space>
          }
        />
        {isLotModel(lot) && lot.priceStepFormatted !== null && (
          <InfoBlock
            title="Шаг аукциона"
            text={
              isLotModel(lot) && lot.priceStepOfTotalFormatted === null
                ? lot.priceStepFormatted
                : `${lot.priceStepFormatted} (${lot.priceStepOfTotalFormatted} %)`
            }
          />
        )}

        <InfoBlock
          title="Электронная площадка"
          text={
            !userStore.authorized ||
            tariffUser.tariffInfo === null ||
            tariffUser.tariffInfo.access_to_source === false ? (
              <IconTextAuth />
            ) : (
              <DigitalPlatform
                source={source}
                className={s['main-info__digital-platform']}
                lotUrl={lotUrl}
                size="small"
              />
            )
          }
        />

        {!userStore.authorized ? (
          <InfoBlock title="Шаг понижения цены" text={<IconText text="Зарегистрируйтесь для просмотра" />} />
        ) : (
          isDowngradeAuction &&
          isLotModel(lot) &&
          lot.priceDecreaseStepFormatted !== null && (
            <InfoBlock title="Шаг понижения цены" text={lot.priceDecreaseStepFormatted} />
          )
        )}
        {isLotModel(lot) && isDowngradeAuction && lot.cutoffPriceFormatted !== null && (
          <InfoBlock title="Цена отсечения" text={lot.cutoffPriceFormatted} />
        )}
        <InfoBlock title="Субъект местонахождения имущества" text={city} />
        {!userStore.authorized || tariffUser.tariffInfo === null || tariffUser.tariffInfo.access_to_lot === false ? (
          <InfoBlock title="Окончание приема заявок" text={<IconTextAuth />} />
        ) : (
          isLotModel(lot) &&
          timelineModel.bidEndTimeStage && <InfoBlock title="Окончание приема заявок" text={lot.formattedBidEndTime} />
        )}
        <Divider className={s['main-info__divider']} />
        {/* <Typography.Text type="secondary">{pluralizePreselected(viewsCount, 'просмотр')}</Typography.Text> */}
      </Space>
    </div>
  );
};

export default observer(MainInfo);
