import { Form, Modal, Typography, Input as AntInput, Checkbox } from 'antd';
import CloseCircledIcon from 'components/icons/CloseCircle';
import { Button } from 'components/Button';
import MaskedInput from 'react-text-mask';
import cn from 'classnames';
import { LINKS } from 'config/links';
import s from '../modals.module.scss';
import React, { useEffect, useMemo, useRef } from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { useNotificationModal } from 'components/NotificationModal/NotificationModal.context';
import { useRootStore } from 'stores/RootStore';
import { ValueModel } from 'models/ValueModel';
import successImg from 'assets/audit-legal/succsess.png';
import { FormWorktStore } from 'stores/FormWorktStore';
import { TariffModel } from 'models/TariffModel';
import { TariffPricingBillingCycle } from 'entities/tariffs';
import { numberWithSpaces } from 'utils/function';
import { useTariffsStore } from 'stores/TariffsStore/context';

export const ModalWork = observer(
  ({ onClose, data, isYear = false }: { onClose: () => void; data: TariffModel | null; isYear?: boolean }) => {
    const refModalTimer = useRef<NodeJS.Timeout>();
    const { subscriptionTariff } = useTariffsStore();
    const { onChangeNotificationModalOpen } = useNotificationModal({
      title: 'Заявка отправлена!',
      desc: 'Наш менеджер скоро свяжется с вами',
      img: successImg,
      label: 'Закрыть окно',
    });
    const rootStore = useRootStore();
    const { fields, submitForm } = useLocalStore(() => new FormWorktStore({ rootStore }));

    const handleInputChange = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const valueModel = (fields as any)[e.target.id] as ValueModel;
        valueModel.change(e.target.value);
      },
      [fields.email, fields.name, fields.phone],
    );

    const handlePhoneChange = React.useCallback(
      (e: any) => {
        fields.phone.change(e.target.value);
      },
      [fields.phone],
    );

    const handleFormSubmit = React.useCallback(async () => {
      submitForm({
        name: fields.name.value,
        phone: fields.phone.value,
        email: fields.email.value,
        corp_client_data: fields.company.value,
      })
        .then((res) => {
          if (!res.isError) {
            onClose();
            onChangeNotificationModalOpen(true);
            fields.resetFull();
            refModalTimer.current = setTimeout(() => {
              onChangeNotificationModalOpen(false);
            }, 5000);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }, [fields]);

    const handleFormPaySubmit = React.useCallback(async () => {
      if (data && !fields.validate()) {
        let option = -1;

        if (isYear) {
          option = data.pricing_options.find((item) => item.billing_cycle === TariffPricingBillingCycle.year)?.id || -1;
        } else {
          option =
            data.pricing_options.find((item) => item.billing_cycle === TariffPricingBillingCycle.month)?.id || -1;
        }

        subscriptionTariff({
          plan_option: option,
          name: fields.name.value,
          phone: fields.phone.value,
          email: fields.email.value,
          corp_client_data: fields.company.value,
        }).then((res) => {
          if (!res.isError) {
            window.location.href = res.data.payment_url;
          }
        });
      }
    }, [fields, data, isYear]);

    const isPayment = useMemo(() => {
      return data && data.name !== 'Профи Макс';
    }, [data]);

    const price = useMemo(() => {
      if (data) {
        const typePrice = isYear ? TariffPricingBillingCycle.year : TariffPricingBillingCycle.month;
        return data.is_free || data.is_trial
          ? '0'
          : data.pricing_options.find((item) => item.billing_cycle === typePrice)?.price || '0';
      }
      return '';
    }, [data, isYear]);

    useEffect(() => {
      if (data && data.name === 'Профи Макс') {
        fields.agreePay.change(true);
      } else {
        fields.agreePay.change(false);
      }
    }, [data]);

    useEffect(() => {
      return () => {
        refModalTimer.current && clearTimeout(refModalTimer.current);
      };
    }, []);

    return (
      <Modal
        classNames={{ body: s.popup__body__reg, content: s.popup__card }}
        className={s.popup__reg}
        open={Boolean(data)}
        footer={false}
        closeIcon={<CloseCircledIcon className={s.popup__close__reg} />}
        width={447}
        onCancel={onClose}
        centered
      >
        <div className={s.content}>
          <h4 className={s.form__title}>Начните работу с нами</h4>
          {isPayment && (
            <p className={s.form__desc}>
              {numberWithSpaces(parseInt(price))} ₽ в {isYear ? 'год' : 'месяц'}
            </p>
          )}

          <Form className={s.form} layout="vertical" autoComplete="off">
            <Form.Item name="name" className={s.form__contol} colon rules={[{ required: true }]}>
              <AntInput
                className={s.form__input}
                status={fields.name.isError ? 'error' : undefined}
                value={fields.name.value || undefined}
                onChange={handleInputChange}
                size="large"
                placeholder="Имя*"
                id={'name'}
              />
              <Typography.Text type="danger" className={s.form__input__error}>
                {fields.name.error}
              </Typography.Text>
            </Form.Item>
            <Form.Item name="phone" colon className={s.form__contol} rules={[{ required: true }]}>
              <div className={s.form__phone__wrap}>
                <MaskedInput
                  className={cn(s.form__input, s.form__phone__input, fields.phone.error && s.form__phone_error)}
                  mask={[
                    '+',
                    '7',
                    ' ',
                    '(',
                    /[1-9]/,
                    /\d/,
                    /\d/,
                    ')',
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                  ]}
                  placeholder="+7 (ххх) ххх-хх-хх*"
                  id="phone"
                  onChange={handlePhoneChange}
                  name="phone"
                  value={fields.phone.value}
                />
              </div>
              <Typography.Text type="danger" className={s.form__input__error}>
                {fields.phone.error}
              </Typography.Text>
            </Form.Item>
            <Form.Item name="email" colon className={s.form__contol} rules={[{ required: true }]}>
              <AntInput
                className={s.form__input}
                status={fields.email.isError ? 'error' : undefined}
                value={fields.email.value || undefined}
                onChange={handleInputChange}
                size="large"
                id="email"
                placeholder="Электронная почта* "
              />
              <Typography.Text type="danger" className={s.form__input__error}>
                {fields.email.error}
              </Typography.Text>
            </Form.Item>
            <Form.Item name="name" className={s.form__contol} colon rules={[{ required: true }]}>
              <AntInput
                className={s.form__input}
                status={fields.company.isError ? 'error' : undefined}
                value={fields.company.value || undefined}
                onChange={handleInputChange}
                size="large"
                placeholder="Компания*"
                id={'company'}
              />
              <Typography.Text type="danger" className={s.form__input__error}>
                {fields.name.error}
              </Typography.Text>
            </Form.Item>
            <Button
              className={s.submint}
              onClick={handleFormSubmit}
              disabled={!fields.agree.value || !fields.agreePay.value}
              outline
            >
              Оставить заявку
            </Button>
            {isPayment && (
              <Button
                className={s.submint + ' ' + s.pay}
                onClick={handleFormPaySubmit}
                disabled={!fields.agree.value || !fields.agreePay.value}
                fillDisabled
              >
                Оплатить онлайн
              </Button>
            )}

            {isPayment && (
              <div className={s.margin}>
                <Checkbox
                  onChange={() => {
                    fields.agreePay.change(!fields.agreePay.value);
                  }}
                  checked={fields.agreePay.value}
                  className={s.checkbox + ' ' + s.checkboxMargin16}
                >
                  <p className={s.form__text__info}>
                    Соглашаюсь с {isYear ? 'ежегодным' : 'ежемесячными'} списаниями в размере <br />{' '}
                    {numberWithSpaces(parseInt(price))} ₽
                  </p>
                </Checkbox>
              </div>
            )}
            <div className={!isPayment ? s.margin : ''}>
              <Checkbox
                onChange={() => {
                  fields.agree.change(!fields.agree.value);
                }}
                checked={fields.agree.value}
                className={s.checkbox + ' ' + (!isPayment ? s.checkboxMargin16 : s.checkboxMargin30)}
              >
                {!isPayment ? (
                  <p className={s.form__text__info}>
                    Я принимаю{' '}
                    <Typography.Link className={s['initial__step__link']} href={LINKS.agreement} target="_blank">
                      Пользовательское соглашение
                    </Typography.Link>{' '}
                    и{' '}
                    <Typography.Link className={s['initial__step__link']} href={LINKS.politics} target="_blank">
                      Политику обработки персональных данных
                    </Typography.Link>{' '}
                  </p>
                ) : (
                  <p className={s.form__text__info}>
                    Я принимаю{' '}
                    <Typography.Link className={s['initial__step__link']} href={LINKS.agreement} target="_blank">
                      Пользовательское соглашение
                    </Typography.Link>
                    , соглашаюсь с{' '}
                    <Typography.Link className={s['initial__step__link']} href={LINKS.politics} target="_blank">
                      Политикой обработки персональных данных
                    </Typography.Link>{' '}
                    и размером последующих списаний
                  </p>
                )}
              </Checkbox>
            </div>
          </Form>
        </div>
      </Modal>
    );
  },
);
