import * as React from 'react';
import { BaseIconProps } from '../commonProps';

const InfoTwoIcon = (props: BaseIconProps): React.ReactElement<BaseIconProps> => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.0002 15.8334C10.2212 15.8334 10.4331 15.7456 10.5894 15.5893C10.7457 15.433 10.8335 15.2211 10.8335 15L10.8335 8.33337C10.8335 8.11236 10.7457 7.9004 10.5894 7.74412C10.4331 7.58784 10.2212 7.50004 10.0002 7.50004C9.77915 7.50004 9.56719 7.58784 9.41091 7.74412C9.25463 7.9004 9.16683 8.11236 9.16683 8.33337L9.16683 15C9.16683 15.2211 9.25463 15.433 9.41091 15.5893C9.56719 15.7456 9.77915 15.8334 10.0002 15.8334Z"
        fill="#252525"
      />
      <path
        d="M9.16683 5.00004C9.16683 5.46028 9.53993 5.83337 10.0002 5.83337C10.4604 5.83337 10.8335 5.46028 10.8335 5.00004C10.8335 4.5398 10.4604 4.16671 10.0002 4.16671C9.53993 4.16671 9.16683 4.5398 9.16683 5.00004Z"
        fill="#252525"
      />
    </svg>
  );
};

export default InfoTwoIcon;
