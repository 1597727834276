import { action, computed, makeObservable, observable } from 'mobx';

import { ValueModel } from 'models/ValueModel';
import { emailValidator, phoneStringValidator, stringLengthValidator } from 'utils/validator';

export class FormPaymentStoreModel {
  readonly name: ValueModel<string> = new ValueModel<string>('', [
    stringLengthValidator({ minLength: 1, maxLength: 100 }),
  ]);
  readonly phone: ValueModel<string> = new ValueModel<string>('', [phoneStringValidator]);
  readonly email: ValueModel<string> = new ValueModel<string>('', [emailValidator]);
  readonly agree: ValueModel<boolean> = new ValueModel<boolean>(false);
  readonly agreePay: ValueModel<boolean> = new ValueModel<boolean>(false);

  isValidateOnChange: boolean = false;

  constructor() {
    makeObservable(this, {
      isValidateOnChange: observable,

      hasErrors: computed,

      validate: action.bound,
      setIsValidateOnChange: action.bound,
    });
  }

  get maxDescriptionLength(): number {
    return 300;
  }

  get hasErrors(): boolean {
    return this.name.isError || this.email.isError || this.phone.isError;
  }

  validate(): boolean {
    const nameHasError = this.name.validate();
    const emailHasError = this.email.validate();
    const phoneHasError = this.phone.validate();

    return nameHasError || emailHasError || phoneHasError;
  }

  setIsValidateOnChange(value: boolean): void {
    this.isValidateOnChange = value;
  }

  resetFull(): void {
    this.name.reset();
    this.email.reset();
    this.phone.reset();
    this.agree.reset();
  }
}
