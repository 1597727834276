// export enum UserTariffType{
//   NO_AUTH = 1,
//   PAID = 4,
//   BASIC = 3,
//   TRIAL = 2
// }

export type UserTariffType = 'NO_AUTH' | 'PAID' | 'BASIC' | 'TRIAL';
export type UserTariffTypeKey = 1 | 2 | 3 | 4;
export const userTariffType: { [key in UserTariffTypeKey]: UserTariffType } = {
  1: 'NO_AUTH',
  2: 'TRIAL',
  3: 'BASIC',
  4: 'PAID',
};

export interface IUser {
  phone: string;
  userId: number;
  email: string | null;
  firstName: string;
  lastName: string;
  patronymic: string;
  userType: string;
  region: string;
  tariffType: UserTariffType;
  corpClientData: string;
}
