import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { BaseInput } from 'components/BaseInput';
import { MultiSelect } from 'components/MultiSelect';
import { useLotListStore } from 'stores/LotListCatalogStore/context';
import { usePermission } from 'hooks/usePopupFilterAuth';

type CityProps = {
  className?: string;
};

const SourceArea = ({ className }: CityProps): React.ReactElement<CityProps> => {
  const { filters } = useLotListStore();
  const { onShowPermissionPopup } = usePermission();

  return (
    <BaseInput className={className} title="Площадка торгов">
      <MultiSelect<string>
        options={filters.areas.options}
        selectedOptions={filters.areas.selectedOptions}
        value={filters.areas.selectedValue.value}
        onChange={filters.areas.toggleValue}
        onSearch={filters.areas.handleSearch}
        searchValue={filters.areas.searchValue.value}
        isOptionsLoading={false}
        dropdownStyle={{
          zIndex: 1300,
        }}
        offSelect={onShowPermissionPopup}
      />
    </BaseInput>
  );
};

export default observer(SourceArea);
