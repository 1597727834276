import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import s from './NotificationModal.module.scss';
import { Modal } from 'antd';
import CloseCircledIcon from 'components/icons/CloseCircle';
import { NotificationModalContext } from './NotificationModal.context';
import { Button } from 'components/Button';

export const NotificationModal = observer(() => {
  const { body, open, onChangeNotificationModalOpen } = useContext(NotificationModalContext);
  const onClose = () => {
    onChangeNotificationModalOpen(false);
  };
  return (
    <Modal
      classNames={{ body: s.popup__body__reg, content: s.popup__card }}
      className={s.popup__reg}
      open={open}
      footer={false}
      closeIcon={<CloseCircledIcon className={s.popup__close__reg} />}
      width={447}
      onCancel={onClose}
      centered
    >
      <div className={s.content}>
        <h4 className={s.content__title}>{body.title}</h4>
        <p className={s.content__text} dangerouslySetInnerHTML={{ __html: body.desc }} />
        <img className={s.content__img} src={body.img} alt="" />
        {body.label && (
          <Button className={s.btn} onClick={onClose}>
            {body.label}
          </Button>
        )}
      </div>
    </Modal>
  );
});
