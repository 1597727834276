import * as React from 'react';
import { BaseIconProps } from '../commonProps';

const CheckIcon = (props: BaseIconProps): React.ReactElement<BaseIconProps> => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M18.5993 3.69251L7.08343 15.2075C7.00601 15.2852 6.914 15.3469 6.81268 15.389C6.71136 15.4311 6.60273 15.4527 6.49302 15.4527C6.38331 15.4527 6.27467 15.4311 6.17335 15.389C6.07204 15.3469 5.98002 15.2852 5.9026 15.2075L1.44927 10.75C1.37184 10.6723 1.27983 10.6106 1.17851 10.5685C1.07719 10.5264 0.968562 10.5048 0.858851 10.5048C0.749139 10.5048 0.640508 10.5264 0.539189 10.5685C0.43787 10.6106 0.345859 10.6723 0.268434 10.75C0.190704 10.8274 0.129027 10.9194 0.0869419 11.0208C0.0448572 11.1221 0.0231934 11.2307 0.0231934 11.3404C0.0231934 11.4501 0.0448572 11.5588 0.0869419 11.6601C0.129027 11.7614 0.190704 11.8534 0.268434 11.9308L4.72343 16.385C5.19339 16.8541 5.83026 17.1175 6.49427 17.1175C7.15827 17.1175 7.79514 16.8541 8.2651 16.385L19.7801 4.87251C19.8577 4.7951 19.9193 4.70314 19.9613 4.6019C20.0033 4.50065 20.0249 4.39212 20.0249 4.28251C20.0249 4.17289 20.0033 4.06436 19.9613 3.96312C19.9193 3.86188 19.8577 3.76992 19.7801 3.69251C19.7027 3.61478 19.6107 3.5531 19.5093 3.51101C19.408 3.46893 19.2994 3.44727 19.1897 3.44727C19.08 3.44727 18.9713 3.46893 18.87 3.51101C18.7687 3.5531 18.6767 3.61478 18.5993 3.69251Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CheckIcon;
