import React from 'react';
import {
  CircleProgressIconFour,
  CircleProgressIconOne,
  CircleProgressIconThee,
  CircleProgressIconTwo,
} from 'components/icons/CircleProgressIcon/CircleProgressIcon';
import { CardInfo } from '../../../../components/CardInfo/CardInfo';
import s from './Online.module.scss';
import { useWidth } from 'hooks/useWidth';

export const Online = () => {
  const { width = window.innerWidth } = useWidth();
  return (
    <div className={s.reports}>
      {width >= 996 ? (
        <>
          <div className={s.reportsHeader}>
            <CircleProgressIconOne />
            <div className={s.reportsSp} />
            <CircleProgressIconTwo />
            <div className={s.reportsSp} />
            <CircleProgressIconThee />
          </div>
          <div className={s.reportsGrid}>
            <CardInfo
              className={s.reportsGridItemOne}
              title="Оставить заявку"
              desc="Выберите подходящий объект и оставьте заявку <br />на сайте"
              type="text"
            />
            <CardInfo
              className={s.reportsGridItemTwo}
              title="Согласовать дату и время показа"
              desc="С вами свяжется менеджер для согласования <br />времени и даты проведения видеозвонка"
              type="text"
            />
            <CardInfo
              className={s.reportsGridItemThree}
              title="Подключиться к трансляции"
              desc="В согласованное время подключитесь к показу. <br />Мы проведем онлайн-экскурсию <br />по выбранному объекту"
              type="text"
            />
          </div>
        </>
      ) : (
        <>
          <div className={s.row}>
            <CircleProgressIconOne />
            <CardInfo
              title="Оставить заявку"
              desc="Выберите подходящий объект и оставьте заявку <br />на сайте"
              type="text"
            />
            <div className={s.spVertical + ' ' + s.spVerticalBottom} />
          </div>
          <div className={s.row}>
            <CircleProgressIconTwo />
            <CardInfo
              title="Согласовать дату и время показа"
              desc="С вами свяжется менеджер для согласования <br />времени и даты проведения видеозвонка"
              type="text"
            />
            <div className={s.spVertical + ' ' + s.spVerticalTop} />
            <div className={s.spVertical + ' ' + s.spVerticalBottom} />
          </div>
          <div className={s.row}>
            <CircleProgressIconThee />
            <CardInfo
              title="Подключиться к трансляции"
              desc="В согласованное время подключитесь к показу. <br />Мы проведем онлайн-экскурсию <br />по выбранному объекту"
              type="text"
            />
            <div className={s.spVertical + ' ' + s.spVerticalTop} />
          </div>
        </>
      )}
    </div>
  );
};
