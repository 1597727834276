import * as React from 'react';

import { BaseIconProps } from '../commonProps';

type ArrowRoundIconProps = BaseIconProps;

const ArrowRoundIcon = (props: ArrowRoundIconProps): React.ReactElement<ArrowRoundIconProps> => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <circle cx="20" cy="20" r="19.5" transform="rotate(-180 20 20)" stroke="#5482E0" />
      <path d="M22 27L15 19.5L22 12" stroke="#5482E0" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowRoundIcon;
