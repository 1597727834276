import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { PageContainer } from 'components/PageContainer';
import { TariffsStoreProvider, useTariffsStore } from 'stores/TariffsStore/context';

import s from './TariffsPage.module.scss';
import { Tariffs } from './Tariffs/Tariffs';
import { FormTariff } from 'components/FormTariff/FormTariff';
import { Navigate } from 'react-router-dom';
import { Spin } from 'antd';

type TariffsPageProps = {
  className?: string;
};

const TariffsContent = observer(({ className }: { className?: string }) => {
  const { tariffs } = useTariffsStore();
  if (tariffs.loadingStage.isLoading) {
    return (
      <div className={s.loading}>
        <Spin size="large" />
      </div>
    );
  }

  if (tariffs.loadingStage.isFinished && tariffs.loadingStage.isError) {
    return <Navigate to={'/'} />;
  }
  return (
    <>
      <PageContainer className={cn(s['lot-page'], s['lot-page_botttom'], className)}>
        <h1 className={s.title}>Тарифы</h1>
        <p className={s.text}>Выберите тариф, соответствующий вашим потребностям</p>
      </PageContainer>
      <Tariffs />
      <PageContainer className={cn(s['lot-page'], className)} withoutBottomPadding withoutTopPadding>
        <FormTariff className={s.form} />
      </PageContainer>
    </>
  );
});

const TariffsPage = ({ className }: TariffsPageProps): React.ReactElement<TariffsPageProps> => {
  return (
    <TariffsStoreProvider>
      <TariffsContent className={className} />
    </TariffsStoreProvider>
  );
};

export default observer(TariffsPage);
