import { IReactionDisposer, action, makeObservable, reaction, runInAction } from 'mobx';
import { apiUrls } from 'config/apiUrls';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { IRootStore } from 'stores/RootStore';
import { ILocalStore } from 'types/ILocalStore';
import { BaseResponse } from 'types/meta';
import { apiCustom } from 'utils/api';
import { FormWorkStoreModel } from 'models/FormWorkStoreModel';

type FormPaymentStoreParams = {
  rootStore: IRootStore;
};

export class FormWorktStore implements ILocalStore {
  readonly fields: FormWorkStoreModel = new FormWorkStoreModel();
  readonly submitStage: LoadingStageModel = new LoadingStageModel();

  private readonly rootStore: IRootStore;
  private readonly disposers: IReactionDisposer[] = [];

  constructor({ rootStore }: FormPaymentStoreParams) {
    makeObservable<this>(this, {
      submitForm: action.bound,
    });

    this.rootStore = rootStore;

    if (this.rootStore.userStore.user?.email) {
      this.fields.email.change(this.rootStore.userStore.user.email);
    }

    if (this.rootStore.userStore.user?.phone) {
      this.fields.phone.change(this.rootStore.userStore.user.phone);
    }

    if (this.rootStore.userStore.user?.firstName) {
      this.fields.name.change(
        this.rootStore.userStore.user?.firstName +
          (this.rootStore.userStore.user?.lastName ? ' ' + this.rootStore.userStore.user?.lastName : ''),
      );
    }

    this.disposers.push(
      reaction(
        () => this.rootStore.userStore.authorized,
        (authorized) => {
          if (!authorized) {
            this.fields.resetFull();
          }
        },
      ),
      reaction(
        () => this.rootStore.userStore.user?.email,
        (email) => this.fields.email.change(email ?? ''),
      ),
      reaction(
        () => this.rootStore.userStore.user?.firstName,
        (name) => this.fields.name.change(name ?? ''),
      ),
      reaction(
        () => this.rootStore.userStore.user?.phone,
        (phone) => this.fields.phone.change(phone ?? ''),
      ),
    );
  }

  async submitForm(data: {
    name: string;
    phone: string;
    email: string;
    corp_client_data: string;
  }): Promise<BaseResponse> {
    if (this.submitStage.isLoading) {
      return { isError: true };
    }

    this.fields.setIsValidateOnChange(true);

    if (this.fields.validate()) {
      return { isError: true };
    }

    this.submitStage.loading();

    const values = { ...data };

    const response = await apiCustom<object>({
      url: `${apiUrls.tariffSupportRequests}`,
      method: 'POST',
      data: values,
    });

    return runInAction(() => {
      if (response.isError) {
        this.submitStage.error();

        return { isError: true };
      }

      this.submitStage.success();

      return { isError: false };
    });
  }

  destroy(): void {
    this.disposers.forEach((disposer) => disposer());
  }
}
