export class VKMetricEvents {
  private readonly trm: any;
  private readonly VK_ID = 3620107;

  constructor() {
    this.trm = (window as any)._tmr;
  }

  checkYm = () => {
    if (window !== undefined && '_tmr' in window) {
      return true;
    }

    return false;
  };
  registerMob = () => {
    if (this.checkYm()) {
      (window as any)._tmr.push({ type: 'reachGoal', id: this.VK_ID, goal: 'register_mob' });
    }
  };
}

export const vkEvents = new VKMetricEvents();
