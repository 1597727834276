import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { BaseInput } from 'components/BaseInput';
import { RangeFilter } from 'components/RangeFilter';
import { useLotListStore } from 'stores/LotListCatalogStore/context';

type PriceProps = {
  className?: string;
  title?: string;
  flat?: boolean;
};

const Price = ({ title = '', flat = false }: PriceProps): React.ReactElement<PriceProps> => {
  const { filters } = useLotListStore();

  return (
    <BaseInput title={title}>
      <RangeFilter
        from={filters.price.from.value}
        to={filters.price.to.value}
        valueMin={filters.price.valueMin.value}
        valueMax={filters.price.valueMax.value}
        onChangeMin={filters.price.valueMin.change}
        onChangeMax={filters.price.valueMax.change}
        placeholderPostfix="₽"
        placeholderTo={filters.price.valueMax.value || 0}
        flat={flat}
        seporatorHide={true}
        placeholderSufix={{
          from: 'от  ',
          to: 'до  ',
        }}
      />
    </BaseInput>
  );
};

export default observer(Price);
