import * as React from 'react';
import { BaseIconProps } from '../commonProps';

const ArrowTopRight = (props: BaseIconProps): React.ReactElement<BaseIconProps> => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_174_3530)">
        <path
          d="M11.6669 0H1.91694C1.31785 0 0.833605 0.485333 0.833605 1.08333C0.833605 1.68133 1.31785 2.16667 1.91694 2.16667H11.6669C11.8609 2.16667 12.0483 2.19267 12.2281 2.24033L0.317688 14.1507C-0.105896 14.5743 -0.105896 15.259 0.317688 15.6826C0.528938 15.8938 0.806271 16 1.0836 16C1.36094 16 1.63827 15.8938 1.84952 15.6826L13.7599 3.77217C13.8076 3.95092 13.8336 4.13942 13.8336 4.33333V14.0833C13.8336 14.6813 14.3179 15.1667 14.9169 15.1667C15.516 15.1667 16.0003 14.6813 16.0003 14.0833V4.33333C16.0003 1.9435 14.0568 0 11.6669 0Z"
          fill="#5482E0"
        />
      </g>
      <defs>
        <clipPath id="clip0_174_3530">
          <rect width="16.0003" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowTopRight;
