import { FilePdfOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import * as React from 'react';

import { ElementId } from 'config/ids';
import { LotModel, LotPublicModel } from 'models/LotModel';
import { getFileName } from 'utils/getFileName';

import Section from '../Section';

import s from './DocsSection.module.scss';
import { useModalFilterText, useModalText } from 'components/ModalText/ModalText.context';
import { IconText } from 'components/IconText/IconText';

type DocsSectionProps = {
  lot: LotModel | LotPublicModel;
  id: ElementId;
  auth?: boolean;
  hideTitle?: boolean;
  print?: boolean;
};

const DocsSection: React.FC<DocsSectionProps> = ({ lot, id, auth = false, hideTitle = false, print = false }) => {
  const { changeMsg, toggleOpen } = useModalText();
  const { changeMsg: changeMsgFilter, toggleOpen: toggleOpenFilter } = useModalFilterText();
  if (lot.documents.length < 1) {
    return null;
  }
  const isLinkError = Boolean(lot.documents.find((item) => item.url === '#'));
  return (
    <Section
      title="Документы лота"
      subtitle={
        !auth || isLinkError ? (
          <IconText text={!auth ? 'Зарегистрируйтесь для просмотра' : 'Оформите тариф для просмотра'} />
        ) : undefined
      }
      id={id}
      className={'section-navigation ' + id}
      hideTitle={hideTitle}
      grid={print}
    >
      {lot.documents.map((document, index) => (
        <div key={document.url + '-' + index} className={`${s.section__document}`}>
          <Typography.Text type="secondary">{getFileName(document.name)}</Typography.Text>
          <Typography.Link
            className={s.section__link}
            href={document.url}
            onClick={(e) => {
              if (!auth || document.url === '#') {
                e.preventDefault();
                e.stopPropagation();
                if (auth) {
                  changeMsgFilter('Оформите тариф для просмотра документов лота');
                  toggleOpenFilter(true);
                } else {
                  changeMsg(
                    'Подключите пробный период на 3 дня за 1р. Далее полная стоимость тарифа - 5 000 р./месяц. Отменить подписку можно в любой момент.',
                  );
                  toggleOpen(true);
                }
              }
            }}
          >
            <FilePdfOutlined className={s['section__document-icon']} />
            {document.name}
          </Typography.Link>
        </div>
      ))}
    </Section>
  );
};

export default React.memo(DocsSection);
