import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';
import bannerImg from 'assets/escort/banner.jpg';
import bannerMobileImg from 'assets/escort/banner-mobile.png';
import { BannerFull } from 'pages/ServicesInfoPage/components/BannerFull/BannerFull';
import s from './EscortPage.module.scss';
import { ServicesStoreProvider } from 'stores/ServicesStore/context';
import { FaqEscort } from 'pages/ServicesInfoPage/components/Faq/Faq';
import { PageContainer } from 'components/PageContainer';
import { RouteFullPath } from 'config/routes';
import { FormServicesOrder } from 'components/FormServicesOrder/FormServicesOrder';
import { CardInteresting } from 'pages/ServicesInfoPage/components/CardInteresting/CardInteresting';
import { useNavigate } from 'react-router-dom';
import legalImg from 'assets/escort/legal.png';
import onlineImg from 'assets/escort/online.png';
import { GridProcessSection } from './components/GridProcess/GridProcess';
import sellImg from 'assets/escort/sell.png';
import { SectionWork } from './components/SectionWork/SectionWork';
import { ymEvents } from 'utils/ym';

const ServisesInfoEscortPage = () => {
  const navigate = useNavigate();
  return (
    <ServicesStoreProvider>
      <BannerFull
        image={{ 540: bannerImg, 240: bannerMobileImg }}
        title={'Сопровождение сделки'}
        description={'Комплексная услуга, которая позволяет обеспечить безопасность и юридическую чистоту сделки'}
      />

      <PageContainer className={cn(s['lot-page'], s['lot-page-padding'])}>
        <h2 className={s.title + ' ' + s.titleSection + ' ' + s.titleSectionFirst}>
          Снижайте трудозатраты <br className={s.hide} />и минимизируйте риски
        </h2>
        <SectionWork />
      </PageContainer>

      <PageContainer className={cn(s['lot-page'], s['lot-page-padding'])} withoutBottomPadding>
        <h2 className={s.title + ' ' + s.titleSection + ' ' + s.titleSectionProcess}>
          Мы пройдем весь процесс участия <br className={s.hide} />в торгах за вас
        </h2>
        <GridProcessSection />
        <div className={s.smallBanner}>
          <div className={s.smallBannerContent}>
            <p className={s.title + ' ' + s.smallBannerTitle}>Стоимость</p>
            <p className={s.text + ' ' + s.smallBannerText}>1% от начальной цены лота, но не более 100 000 ₽</p>
          </div>
          <img
            src={sellImg}
            className={s.smallBannerImg}
            width={60}
            height={60}
            loading="lazy"
            decoding="async"
            alt=""
          />
        </div>
      </PageContainer>

      <PageContainer className={cn(s['lot-page'], s['lot-page-mobile'])} withoutBottomPadding withoutTopPadding>
        <section className={s.formSection} id="form">
          <FormServicesOrder
            title={`Оставьте заявку <br/>и делегируйте участие в торгах нам!`}
            onSuccess={() => {
              ymEvents.ostavitZayavkuSs();
            }}
          />
        </section>
        <section className={s.servicesMoreSection}>
          <h2 className={s.title + ' ' + s.titleMore}>Может быть интересно</h2>
          <div className={s.rowMore}>
            <CardInteresting
              title={'Онлайн-показ'}
              description={'Смотрите объекты в реальном времени, не тратя время на дорогу'}
              image={onlineImg}
              onClick={() => navigate('/' + RouteFullPath.services_online)}
            />
            <CardInteresting
              title={'Юридический аудит'}
              description={'Минимизируйте риски, доверив проверку документов профессионалам'}
              image={legalImg}
              onClick={() => navigate('/' + RouteFullPath.services_audit_legal)}
            />
          </div>
        </section>
        <section className={s.sectionFaq}>
          <h2 className={s.title + ' ' + s.titleFaq}>Частые вопросы</h2>
          <FaqEscort />
        </section>
      </PageContainer>
    </ServicesStoreProvider>
  );
};

export default observer(ServisesInfoEscortPage);
