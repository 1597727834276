import * as React from 'react';
import { BaseIconProps } from '../commonProps';

const DocUnSuccessIcon = (props: BaseIconProps): React.ReactElement<BaseIconProps> => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_162_1375)">
        <path
          d="M46.52 40.5706L41.8123 45.0898C41.8116 45.0904 41.8109 45.0911 41.8103 45.0918C41.262 45.6264 40.3644 45.6459 39.8067 45.0938C39.8062 45.0933 39.8057 45.0928 39.8052 45.0923L36.9801 42.3247L36.9801 42.3246C36.1865 41.5471 34.9238 41.5608 34.153 42.3515L34.1521 42.3524C33.3776 43.143 33.39 44.4064 34.1799 45.1803L37.0093 47.9522C37.0094 47.9524 37.0096 47.9525 37.0098 47.9527C38.0618 48.9793 39.4393 49.495 40.8175 49.495C42.1965 49.495 43.5679 48.9788 44.5988 47.9616L44.6037 47.9567L44.6038 47.9568L50.1358 42.6472L46.52 40.5706ZM46.52 40.5706V40.575L47.3667 39.7603C48.1605 38.9965 49.4283 39.0187 50.1939 39.8183L50.1943 39.8187C50.9576 40.6141 50.9325 41.8801 50.1363 42.6468L46.52 40.5706ZM24.5 57.5C24.5 58.6064 23.6064 59.5 22.5 59.5H12.5C5.88364 59.5 0.5 54.1164 0.5 47.5V12.5C0.5 5.88364 5.88364 0.5 12.5 0.5H32.5C39.1164 0.5 44.5 5.88364 44.5 12.5V17.5C44.5 18.6064 43.6064 19.5 42.5 19.5C41.3936 19.5 40.5 18.6064 40.5 17.5V12.5C40.5 8.08886 36.9111 4.5 32.5 4.5H12.5C8.08886 4.5 4.5 8.08886 4.5 12.5V47.5C4.5 51.9111 8.08886 55.5 12.5 55.5H22.5C23.6064 55.5 24.5 56.3936 24.5 57.5ZM32.5 13C33.6064 13 34.5 13.8936 34.5 15C34.5 16.1064 33.6064 17 32.5 17H12.5C11.3936 17 10.5 16.1064 10.5 15C10.5 13.8936 11.3936 13 12.5 13H32.5ZM22.5 25.5C23.6064 25.5 24.5 26.3936 24.5 27.5C24.5 28.6064 23.6064 29.5 22.5 29.5H12.5C11.3936 29.5 10.5 28.6064 10.5 27.5C10.5 26.3936 11.3936 25.5 12.5 25.5H22.5ZM10.5 40C10.5 38.8936 11.3936 38 12.5 38H17.5C18.6064 38 19.5 38.8936 19.5 40C19.5 41.1064 18.6064 42 17.5 42H12.5C11.3936 42 10.5 41.1064 10.5 40ZM59.5 42.5C59.5 51.8714 51.8714 59.5 42.5 59.5C33.1286 59.5 25.5 51.8714 25.5 42.5C25.5 33.1286 33.1286 25.5 42.5 25.5C51.8714 25.5 59.5 33.1286 59.5 42.5ZM55.5 42.5C55.5 35.3314 49.6686 29.5 42.5 29.5C35.3314 29.5 29.5 35.3314 29.5 42.5C29.5 49.6686 35.3314 55.5 42.5 55.5C49.6686 55.5 55.5 49.6686 55.5 42.5Z"
          fill="currentColor"
          stroke="#F3F4F7"
        />
      </g>
      <defs>
        <clipPath id="clip0_162_1375">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DocUnSuccessIcon;
