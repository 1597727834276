import React, { useEffect } from 'react';
import { Form, Input as AntInput, Typography } from 'antd';
import cn from 'classnames';
import s from './FormServicesOrder.module.scss';
import { observer, useLocalStore } from 'mobx-react-lite';
import { useRootStore } from 'stores/RootStore';
import { FormServiceOrderStore } from 'stores/FormServiceOrderStore';
import { ValueModel } from 'models/ValueModel';
import { Button } from 'components/Button';
import MaskedInput from 'react-text-mask';
import { RouteSegment } from 'config/routes';
import { useNotificationModal } from 'components/NotificationModal/NotificationModal.context';
import successImg from 'assets/audit-legal/succsess.png';
import { useSearchParams } from 'react-router-dom';

export const FormServicesOrder = observer(
  ({
    title = `Оставьте заявку на юридический аудит <br />понравившегося объекта`,
    onSuccess,
  }: {
    title?: string;
    onSuccess?: () => void;
  }) => {
    const [searchParams] = useSearchParams();
    const lotLink = searchParams.get('lot') ? window.location.origin + '/lots/' + searchParams.get('lot') : '';
    const { onChangeNotificationModalOpen } = useNotificationModal({
      title: 'Заявка отправлена!',
      desc: 'Наш менеджер скоро свяжется с вами',
      img: successImg,
      label: 'Закрыть окно',
    });
    const rootStore = useRootStore();
    const { fields, submitForm } = useLocalStore(() => new FormServiceOrderStore({ rootStore }));

    useEffect(() => {
      if (lotLink !== '') {
        fields.site.change(lotLink);
      }
    }, [lotLink]);

    const handleInputChange = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const valueModel = (fields as any)[e.target.id] as ValueModel;
        valueModel.change(e.target.value);
      },
      [fields.email, fields.name, fields.site, fields.phone],
    );

    const handlePhoneChange = React.useCallback(
      (e: any) => {
        fields.phone.change(e.target.value);
      },
      [fields.phone],
    );

    const handleFormSubmit = React.useCallback(async () => {
      submitForm({
        name: fields.name.value,
        phone: fields.phone.value,
        email: fields.email.value,
        lot_url: fields.site.value,
      })
        .then((res) => {
          if (!res.isError) {
            onChangeNotificationModalOpen(true);
            fields.resetFull();
            onSuccess && onSuccess();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }, [fields]);

    return (
      <div className={s.paper}>
        <h3 className={s.title} dangerouslySetInnerHTML={{ __html: title }} />
        <Form className={s.form} layout="vertical" autoComplete="off">
          <Form.Item name="name" className={s.form__contol} colon rules={[{ required: true }]}>
            <AntInput
              
              className={s.form__input+ ' ym-record-keys'}
              status={fields.name.isError ? 'error' : undefined}
              value={fields.name.value || undefined}
              onChange={handleInputChange}
              size="large"
              placeholder="Имя*"
              id={'name'}
            />
            <Typography.Text type="danger" className={s.form__input__error}>
              {fields.name.error}
            </Typography.Text>
          </Form.Item>
          <Form.Item name="email" colon className={s.form__contol} rules={[{ required: true }]}>
            <AntInput
              className={s.form__input+ ' ym-record-keys'}
              status={fields.email.isError ? 'error' : undefined}
              value={fields.email.value || undefined}
              onChange={handleInputChange}
              size="large"
              id="email"
              placeholder="Электронная почта* "
            />
            <Typography.Text type="danger" className={s.form__input__error}>
              {fields.email.error}
            </Typography.Text>
          </Form.Item>
          <Form.Item name="phone" colon className={s.form__contol} rules={[{ required: true }]}>
            <div className={s.form__phone__wrap}>
              <MaskedInput
                className={cn(s.form__input, s.form__phone__input, fields.phone.error && s.form__phone_error,' ym-record-keys')}
                mask={[
                  '+',
                  '7',
                  ' ',
                  '(',
                  /[1-9]/,
                  /\d/,
                  /\d/,
                  ')',
                  ' ',
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                ]}
                placeholder="+7 (ххх) ххх-хх-хх*"
                id="phone"
                onChange={handlePhoneChange}
                name="phone"
                value={fields.phone.value}
              />
            </div>
            <Typography.Text type="danger" className={s.form__input__error}>
              {fields.phone.error}
            </Typography.Text>
          </Form.Item>

          <Form.Item name="site" className={s.form__contol} colon rules={[{ required: true }]}>
            <AntInput
              className={s.form__input+ ' ym-record-keys'}
              status={fields.site.isError ? 'error' : undefined}
              value={fields.site.value || undefined}
              onChange={handleInputChange}
              size="large"
              placeholder="Ссылка на лот"
              id="site"
            />
            <Typography.Text type="danger" className={s.form__input__error}>
              {fields.site.error}
            </Typography.Text>
          </Form.Item>
          <Button className={s.submint} onClick={handleFormSubmit}>
            Оставить заявку
          </Button>
          <p className={s.form__text__info}>
            {rootStore.userStore.authorized ? (
              <>
                Нажимая на кнопку “Оставить заявку” вы принимаете{' '}
                <a href={'/' + RouteSegment.online_public_offer}>Публичную оферту</a>
              </>
            ) : (
              <>
                {'Нажимая на кнопку «Оставить заявку» вы даёте '}
                <a href={'/' + RouteSegment.agree}>Согласие на обработку персональных данных</a>, принимаете{' '}
                <a href={'/' + RouteSegment.policy}>Политику обработки персональных данных</a> и{' '}
                <a href={'/' + RouteSegment.online_public_offer}>Публичную оферту</a>
              </>
            )}
          </p>
        </Form>
      </div>
    );
  },
);
