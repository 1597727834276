import { Tooltip, Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { DigitalPlatform } from 'components/DigitalPlatform';
import { getIsElementHeightOverflow } from 'utils/getElementHeightOverflow';

import { LotSource } from '../../../../entities/lot';

import s from './Main.module.scss';
import { IconText } from 'components/IconText/IconText';
import { useUserGetTariffInfo } from 'hooks/usePopupFilterAuth';
import { IconTextAuth } from 'components/IconTextAuth/IconTextAuth';

type MainProps = {
  address?: string | null;
  metro?: string;
  walkingTime?: number;
  source?: LotSource;
  className?: string;
  column?: boolean;
  auth?: boolean;
};

const Main = ({
  address,
  source,
  className,
  column = false,
  auth = false,
}: MainProps): React.ReactElement<MainProps> => {
  const titleRef = React.useRef<HTMLParagraphElement | null>(null);
  const [isTooltipShown, setIsTooltipShown] = React.useState(getIsElementHeightOverflow(titleRef));
  const tariffUser = useUserGetTariffInfo();

  React.useEffect(() => {
    setIsTooltipShown(getIsElementHeightOverflow(titleRef));
  }, [titleRef.current]);

  return (
    <div className={cn(s.main, column ? s.column : '', className)}>
      <div className={s.main__address}>
        <Tooltip
          title={isTooltipShown ? address : null}
          overlayClassName={s['main__address-tooltip']}
          mouseEnterDelay={0.7}
        >
          <Typography.Paragraph className={s['main__address-text']} ref={titleRef}>
            {address}
          </Typography.Paragraph>
        </Tooltip>
        {/* Временно скрыли метро и время пешком, т.к. нет на бэке */}
        {/* <div className={s['main__address-meta']}>
          <MetroStation className={s['main__address-metro']} title={metro} />
          <WalkingDistance time={walkingTime} />
        </div> */}
      </div>
      <div className={s.main__platform}>
        <Typography.Paragraph className={s['main__platform-subtitle']}>Электронная площадка</Typography.Paragraph>
        {tariffUser.tariffInfo?.access_to_source && auth && source ? (
          <DigitalPlatform className={s['main__platform-title']} source={source} size="medium" />
        ) : (
          <IconTextAuth />
        )}
      </div>
    </div>
  );
};

export default React.memo(Main);
