import ClockIcon from 'components/icons/ClockIcon/ClockIcon';
import DocUnSuccessIcon from 'components/icons/DocUnSuccessIcon/DocUnSuccessIcon';
import InfoIcon from 'components/icons/InfoIcon/InfoIcon';
import React from 'react';
import { CardInfo } from '../../../../components/CardInfo/CardInfo';
import s from './Advantage.module.scss';
import { useWidth } from 'hooks/useWidth';
import Slider from 'components/Slider';
import { Pagination } from 'swiper/modules';

export const Advanced = () => {
  const { width = window.innerWidth } = useWidth();
  const pagination = {
    clickable: true,
    renderBullet: (index: number, className: string) => {
      return `<span class="${className} ${s.bullet}" > </span>`;
    },
    el: '.paginaton-service',
  };
  return (
    <section>
      <h2 className={s.title + ' ' + s.titleSection + ' ' + s.titleSectionOur}>Наши преимущества</h2>
      <p className={s.text + ' ' + s.descSection}>
        Экспертиза проводится квалифицированными юристами в области недвижимости
      </p>

      {width >= 768 ? (
        <div className={s.gridOurTop}>
          <CardInfo
            icon={<DocUnSuccessIcon />}
            title={'Полная информация <br />об объекте недвижимости'}
            type="icon_text"
          />
          <CardInfo
            icon={<ClockIcon />}
            title={'Предоставление результата <br />юридического аудита до 5 дней'}
            type="icon_text"
          />
          <CardInfo icon={<InfoIcon />} title={'Подсветим риски <br />до покупки объекта'} type="icon_text" />
        </div>
      ) : (
        <div className={s.slider}>
          <Slider
            spaceBetween={32}
            slideClass={s.slide}
            pagination={pagination}
            loop
            slides={[
              <CardInfo
                icon={<DocUnSuccessIcon />}
                title={'Полная информация <br />об объекте недвижимости'}
                type="icon_text"
              />,
              <CardInfo
                icon={<ClockIcon />}
                title={'Предоставление результата <br />юридического аудита до 5 дней'}
                type="icon_text"
              />,
              <CardInfo icon={<InfoIcon />} title={'Подсветим риски <br />до покупки объекта'} type="icon_text" />,
            ]}
            onSlideChange={() => {}}
            slidesPerView={'auto'}
            modules={[Pagination]}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              481: {
                spaceBetween: 32,
                slidesPerView: 'auto',
              },
            }}
            watchOverflow
          />
          <div className={'paginaton-service ' + s.pagination} />
        </div>
      )}
    </section>
  );
};
