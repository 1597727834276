import { apiUrls } from 'config/apiUrls';
import { ResponseListApi } from 'entities';
import { ITariffData, ITariffHistory, ITariffPricingOption, ITariffTrialPricingOption } from 'entities/tariffs';
import { IReactionDisposer } from 'mobx';
import { ListModel } from 'models/ListModel';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { TariffHistoryModel, TariffModel } from 'models/TariffModel';
import { ValueModel } from 'models/ValueModel';
import { LocalStore } from 'stores/LocalStore';
import { BaseResponse } from 'types/meta';
import { apiCustom } from 'utils/api';

export class TariffsStore extends LocalStore {
  readonly tariffs: ListModel<TariffModel, number> = new ListModel<TariffModel, number>();
  readonly tariffTrial: ValueModel<ITariffTrialPricingOption | null> = new ValueModel<ITariffTrialPricingOption | null>(
    null,
  );
  readonly tariffsHistory: ListModel<TariffHistoryModel, number> = new ListModel<TariffHistoryModel, number>();
  private api = apiCustom;
  private readonly disposers: IReactionDisposer[] = [];
  readonly loadingStage: LoadingStageModel = new LoadingStageModel();
  readonly loadingSubscriptionStage: LoadingStageModel = new LoadingStageModel();
  readonly loadingTariffHistoryStage: LoadingStageModel = new LoadingStageModel();
  readonly loadingTrialStage: LoadingStageModel = new LoadingStageModel();

  private abortController?: AbortController;

  constructor() {
    super();
  }

  subscriptionTariff = async (data: {
    plan_option: number;
    name: string;
    email: string;
    phone: string;
    corp_client_data?: string;
  }): Promise<BaseResponse<{ payment_url: string }, string>> => {
    this.loadingSubscriptionStage.loading();
    const values = { ...data };

    if (data.corp_client_data === null || data.corp_client_data === '') {
      delete values['corp_client_data'];
    }

    const response = await this.api<{ payment_url: string }>({
      url: `${apiUrls.tariffsSubscription}`,
      method: 'POST',
      data: values,
    });

    if (response.isError) {
      this.loadingSubscriptionStage.error();
      const errors = { ...response.data };
      if ('responseCode' in errors) {
        delete errors['responseCode'];
      }
      return {
        isError: true,
        data: Object.values(errors).join('. '),
      };
    }

    this.loadingSubscriptionStage.success();
    return {
      isError: false,
      data: response.data,
    };
  };

  fetchTariffsHistory = async ({
    replace = true,
  }: {
    replace: boolean;
  }): Promise<BaseResponse<ListModel<TariffHistoryModel, number>>> => {
    if (replace) {
      this.tariffsHistory.setIsAllLoaded(false);
      this.tariffsHistory.isReplaceLoading.change(true);
    }

    let request = (this.abortController = new AbortController());

    if (this.tariffsHistory.loadingStage.isLoading && this.abortController) {
      this.abortController.abort();
    }
    this.tariffsHistory.loadingStage.loading();

    const response = await this.api<ITariffHistory[]>({
      url: `${apiUrls.tariffsHistory}?limit=100&offset=0`,
      method: 'GET',
      config: {
        signal: request.signal,
      },
    });

    if (response.isError) {
      this.tariffsHistory.loadingStage.error();
      return { isError: true };
    }
    console.log(response.data);
    this.tariffsHistory.fillByRawData<ITariffHistory>(
      response.data,
      (raw) => {
        const model = TariffHistoryModel.fromJson(raw);
        return {
          entity: model,
          key: model.id,
        };
      },
      replace,
    );

    this.tariffsHistory.isInitialLoading.change(false);
    this.tariffsHistory.isReplaceLoading.change(false);
    this.tariffsHistory.total.change(response.data.length);
    this.tariffsHistory.setIsAllLoaded(true);
    this.tariffsHistory.loadingStage.success();

    return { isError: false, data: this.tariffsHistory };
  };

  fetchTariffTrial = async () => {
    this.loadingStage.loading();
    const response = await this.api<ITariffTrialPricingOption>({
      url: `${apiUrls.tariffTrial}?limit=100&offset=0`,
      method: 'GET',
    });

    if (response.isError) {
      this.tariffs.loadingStage.error();
      return { isError: true };
    }

    this.tariffTrial.change(response.data);
    this.loadingStage.success();
  };

  fetchTariffs = async ({
    replace = true,
  }: {
    replace: boolean;
  }): Promise<BaseResponse<ListModel<TariffModel, number>>> => {
    if (replace) {
      this.tariffs.setIsAllLoaded(false);
      this.tariffs.isReplaceLoading.change(true);
    }

    let request = (this.abortController = new AbortController());

    if (this.tariffs.loadingStage.isLoading && this.abortController) {
      this.abortController.abort();
    }
    this.tariffs.loadingStage.loading();

    const response = await this.api<ResponseListApi<ITariffData>>({
      url: `${apiUrls.tariffs}?limit=100&offset=0`,
      method: 'GET',
      config: {
        signal: request.signal,
      },
    });

    if (response.isError) {
      this.tariffs.loadingStage.error();
      return { isError: true };
    }

    this.tariffs.fillByRawData<ITariffData>(
      response.data.results,
      (raw) => {
        const model = TariffModel.fromJson(raw);
        return {
          entity: model,
          key: model.id,
        };
      },
      replace,
    );

    this.tariffs.isInitialLoading.change(false);
    this.tariffs.isReplaceLoading.change(false);
    this.tariffs.total.change(response.data.results.length);
    this.tariffs.setIsAllLoaded(true);
    this.tariffs.loadingStage.success();

    return { isError: false, data: this.tariffs };
  };

  destroy(): void {
    super.destroy();
    this.disposers.forEach((disposer) => disposer());
  }
}
