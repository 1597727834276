import React from 'react';
import Slider from 'components/Slider';
import { Pagination } from 'swiper/modules';
import { useWidth } from 'hooks/useWidth';
import { CardInfo } from 'pages/ServicesInfoPage/components/CardInfo/CardInfo';
import DocIcon from 'components/icons/DocIcon/DocIcon';
import ClockIcon from 'components/icons/ClockIcon/ClockIcon';
import PathIcon from 'components/icons/PathIcon/PathIcon';
import CommmentIcon from 'components/icons/CommmentIcon/CommmentIcon';
import s from './SectionWork.module.scss';

const CARDS = [
  <CardInfo
    key={'cardInfoTime'}
    className={s.cardInfoTime}
    icon={<ClockIcon />}
    title={'Экономия времени'}
    desc={`Избавим от необходимости разбираться <br />в сложностях процедуры и документах, обеспечим полное сопровождение на всех этапах`}
  />,
  <CardInfo
    key={'cardInfoDoc'}
    className={s.cardInfoDoc}
    icon={<DocIcon />}
    title={'Юридический аудит'}
    desc={'Проведем всестороннюю проверку документации объекта и оценим <br />возможные риски'}
  />,
  <CardInfo
    key={'cardInfoPath'}
    className={s.cardInfoPath}
    icon={<PathIcon />}
    title={'Участие в различных торговых процедурах'}
    desc={'Делегируйте нам участие в торгах, и мы будем представлять ваши интересы на всех этапах процесса'}
  />,
  <CardInfo
    key={'cardInfoComment'}
    className={s.cardInfoComment}
    icon={<CommmentIcon />}
    title={'Профессионализм'}
    desc={
      'Наша команда состоит из высококвалифицированных юристов и брокеров с многолетним опытом участия <br />в торгах'
    }
  />,
];

export const SectionWork = () => {
  const { width = window.innerWidth } = useWidth();
  const pagination = {
    clickable: true,
    renderBullet: (index: number, className: string) => {
      return `<span class="${className} ${s.bullet}" > </span>`;
    },
    el: '.paginaton-service',
  };

  return width > 580 ? (
    <div className={s.grid}>{CARDS.map((item) => item)}</div>
  ) : (
    <div className={s.slider}>
      <Slider
        spaceBetween={0}
        slideClass={s.slide}
        pagination={pagination}
        loop
        slides={CARDS}
        onSlideChange={() => {}}
        slidesPerView={1}
        modules={[Pagination]}
        watchOverflow
      />
      <div className={'paginaton-service ' + s.pagination} />
    </div>
  );
};
