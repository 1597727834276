import * as React from 'react';
import { Outlet } from 'react-router-dom';

import AuthPopup from 'components/AuthPopup';
import EmailPopup from 'components/EmailPopup';
import { useScrollToTop } from 'hooks/useScrollTop';
import { WidthProvider } from 'hooks/useWidth';
import { RootStoreContextProvider } from 'stores/RootStore';

import s from './App.module.scss';
import { BugReport } from 'components/BugReport/BugReport';
import { CookiesContextProvider } from 'components/Cookies';
import { ModalTextProvider, ModalTextFilterProvider } from 'components/ModalText/ModalText.context';
import { RouteFullPath } from 'config/routes';
import { HeaderFlat } from 'components/HeaderFlat';
import { FooterLight } from 'components/FooterLight';
import { NotificationModalProvider } from 'components/NotificationModal/NotificationModal.context';

const App: React.FC = () => {
  useScrollToTop();
  const isOffsetOff =
    window.location.pathname === RouteFullPath.home ||
    window.location.pathname === '/' + RouteFullPath.services_audit_legal ||
    window.location.pathname === '/' + RouteFullPath.services_escort ||
    window.location.pathname === '/' + RouteFullPath.services_online;
  return (
    <RootStoreContextProvider>
      <NotificationModalProvider>
        <ModalTextFilterProvider>
          <ModalTextProvider>
            <CookiesContextProvider>
              <WidthProvider>
                <div className={s.app__container}>
                  <HeaderFlat offset={!isOffsetOff} />
                  <Outlet />
                  <FooterLight />
                </div>
                {!isOffsetOff && <BugReport />}
                <AuthPopup />
                <EmailPopup />
                {/* <Onboarding /> */}
              </WidthProvider>
            </CookiesContextProvider>
          </ModalTextProvider>
        </ModalTextFilterProvider>
      </NotificationModalProvider>
    </RootStoreContextProvider>
  );
};

export default App;
